import { apiClient } from 'api/apiClient';
import type { INotificationRequest } from 'api/types/sendEmail.types';

const RESOURCE = '/notifications';

export const postNotification = async (
	data: INotificationRequest
): Promise<void> => {
	try {
		await apiClient.post(`${RESOURCE}`, data);
	} catch (error) {
		throw new Error('Failed to send email');
	}
};

export const saveFile = async (file: File): Promise<{ id: number }> => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('FileType', 'other');

	const { data } = await apiClient.post('/files', formData);
	return data;
};
