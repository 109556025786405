import React from 'react';

interface IBaseButton {
	text: string | React.ReactNode;
	onClick: () => void;
	type?: 'button' | 'submit' | 'reset';
	buttonClass?: string;
	disabled?: boolean;
}

const BaseButton = ({
	text,
	onClick,
	buttonClass,
	type,
	disabled,
}: IBaseButton) => (
	<button
		className={buttonClass}
		onClick={onClick}
		type={type || 'button'}
		disabled={disabled}
	>
		{text}
	</button>
);

BaseButton.displayName = 'BaseButton';

export default BaseButton;
