import { apiClient } from 'api/apiClient';

export interface IPayType {
	accountn: string;
	specAcct: number;
	payType: string;
	defAcct: string;
	id: number;
	seqNum: number;
	confirmOnlinePay: boolean;
}

export interface IGeneralLedgerAccountSummary {
	value: string;
	key: string;
	id: string;
}

export interface IMappedPayType {
	value: number | string;
	label: string;
	id: number;
	accountNumber: string;
	payType: string;
}

export const getPayTypes = async (): Promise<IMappedPayType[]> => {
	const { data: accounts } = (await apiClient.get(
		`/GeneralLedgerAccounts/summaries?$filter=specacct eq 2`
	)) as {
		data: {
			results: IGeneralLedgerAccountSummary[];
		};
	};

	const { data: payTypes } = await apiClient.get(`/PayTypes`);

	const mappedPayTypes = payTypes.map((payType: IPayType) => ({
		label: `${payType.payType} [${payType.defAcct}]`,
		value: `${payType.payType} ${payType.defAcct}`,
		accountNumber: payType.defAcct,
		id: payType.id,
		payType: payType.payType,
	}));

	const mappedAccounts = accounts.results.map(
		(account: IGeneralLedgerAccountSummary) => ({
			label: `${account.key} [${account.value}]`,
			value: `${account.key} ${account.value}`,
			accountNumber: account.key,
		})
	);

	return [...mappedAccounts, ...mappedPayTypes];
};
