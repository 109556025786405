import { isEmpty } from 'lodash';
import { removeSpaces } from 'legacy/utilities/String';
import { isEmail } from 'legacy/utilities/Validation';
import { displayAlertError } from 'legacy/utilities/Response';

export const getValidEmails = (values: string): string => {
	if (!isEmpty(values)) {
		values = values.replace(/[\s,/]+/g, ';');
		const emailArray = values.split(';');

		const validEmails = emailArray
			.map((email) => removeSpaces(email))
			.filter((email) => {
				if (isEmail(removeSpaces(email))) {
					return true;
				} else {
					!isEmpty(email) &&
						displayAlertError(
							`<u>${email}</u>&nbsp;is not a valid email address`
						);
					return false;
				}
			});

		return validEmails.join(';');
	}
	return '';
};
