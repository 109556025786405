import { Button, Form } from 'react-bootstrap';
import { ExpandablePanelSuggestions } from './ExpandablePanelSuggestions';
import { useState } from 'react';
import { usePostSuggestions } from 'api/hooks/usePostSuggestions';
import {
	displayAlertError,
	displayAlertSuccess,
} from 'legacy/utilities/Response';
import { useQueryClient } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import { usePostClearTransactions } from 'api/hooks/useClearTransactions';
import { ExpandablePanelAddOption } from './ExpandablePanelAddOption';
import { useNavigate } from 'react-router-dom';
import URI from 'legacy/defaults/RoutesDefault';
import { ETransactionSuggestionsType } from 'api/resources/transactionSuggestions';
import { ITransactions } from 'api/resources/transactions';
import { AxiosError } from 'axios';

export type TSelectedAddOption = {
	id: string;
	url: string;
} | null;

export const ExpandablePanel = ({
	toggleExpanded,
	row,
	setPage,
}: {
	setPage: (page: number) => void;
	row: ITransactions;
	toggleExpanded: (id: string) => void;
}) => {
	const navigate = useNavigate();
	const [checkedValue, setCheckedValue] = useState<
		'Suggested' | 'Add' | 'Override'
	>('Suggested');
	const [selectedSuggestionsDetails, setSelectedSuggestionsDetails] = useState<
		null | string[]
	>(null);
	const [selectedSuggestion, setSelectedSuggestion] = useState('');
	const [selectedAddOption, setSelectedAddOption] =
		useState<TSelectedAddOption>(null);
	const queryClient = useQueryClient();
	const { mutateAsync: postSuggestions } = usePostSuggestions();
	const { mutateAsync: clearTransactions } = usePostClearTransactions();

	const disableButton =
		(checkedValue === 'Suggested' && !selectedSuggestionsDetails) ||
		(checkedValue === 'Add' && !selectedAddOption);

	const clearTransaction = async () => {
		try {
			await clearTransactions({ id: row.id });
			setPage(1);
			await queryClient.resetQueries({ queryKey: [ECacheKeys.Transactions] });
			displayAlertSuccess('Transaction assigned successfully!');
			toggleExpanded(String(row.id));
		} catch (error) {
			const userError = (error as AxiosError<{ userError: string }>)?.response
				?.data?.userError;
			displayAlertError(
				userError ||
					'There was a problem assigning the transaction, please try again'
			);
		}
		return;
	};

	const onSubmit = async () => {
		if (checkedValue === 'Suggested') {
			const type = selectedSuggestion.split(' ')[0];
			if (type === ETransactionSuggestionsType.PURCHASEORDER) {
				return navigate(
					`${URI.accountsPayable.vendorInvoice.add}?transactionId=${row.id}&PO=${selectedSuggestionsDetails?.[0]}`
				);
			}
			try {
				await postSuggestions({
					id: row.id,
					suggestions: selectedSuggestionsDetails as string[],
				});
				setPage(1);
				await queryClient.resetQueries({ queryKey: [ECacheKeys.Transactions] });
				displayAlertSuccess('Transaction suggestion applied successfully!');
				toggleExpanded(String(row.id));
			} catch (error) {
				const userError = (error as AxiosError<{ userError: string }>)?.response
					?.data?.userError;

				displayAlertError(
					userError ||
						'There was a problem applying the suggestion, please try again'
				);
			}
			return;
		} else if (checkedValue === 'Override') {
			await clearTransaction();
			return;
		}
		return selectedAddOption?.id === 'project-expense'
			? navigate(
					`${selectedAddOption?.url}?transactionId=${row.id}&isProj=true`
			  )
			: navigate(`${selectedAddOption?.url}?transactionId=${row.id}`);
	};

	return (
		<div className="tw-min-h-96 tw-bg-[#faf9f7] tw-w-full tw-p-8 tw-flex tw-flex-col">
			{row.amount < 0 ? (
				<div>
					<p className="tw-text-base tw-m-0 tw-gap-10">
						We are working on Deposits and Credits. Please come back soon. You
						can manually mark this transaction as assigned by choosing Override.
					</p>
					<div className="tw-mt-8">
						<Button className="!tw-rounded-3xl" onClick={clearTransaction}>
							Override
						</Button>
					</div>
				</div>
			) : (
				<>
					<div className="tw-flex tw-gap-10">
						<Form.Check
							onChange={() => setCheckedValue('Suggested')}
							inline
							type="radio"
							label="Suggested"
							checked={checkedValue === 'Suggested'}
						/>
						<Form.Check
							onChange={() => setCheckedValue('Add')}
							inline
							type="radio"
							label="Add"
							checked={checkedValue === 'Add'}
						/>
						<Form.Check
							inline
							type="radio"
							label="Override"
							onChange={() => setCheckedValue('Override')}
							checked={checkedValue === 'Override'}
						/>
					</div>
					<div className="tw-mt-8">
						{checkedValue === 'Suggested' && (
							<ExpandablePanelSuggestions
								selectedSuggestion={selectedSuggestion}
								setSelectedSuggestion={setSelectedSuggestion}
								setSelectedSuggestionsDetails={setSelectedSuggestionsDetails}
								id={row.id}
							/>
						)}
						{checkedValue === 'Add' && (
							<ExpandablePanelAddOption
								selectedAddOption={selectedAddOption}
								setSelectedAddOption={setSelectedAddOption}
							/>
						)}
						{checkedValue === 'Override' && (
							<p className="tw-text-base">
								Manually mark this transaction as assigned.
							</p>
						)}
					</div>
					<div className="tw-mt-8">
						<Button
							className="!tw-rounded-3xl"
							onClick={onSubmit}
							disabled={disableButton}
						>
							{checkedValue === 'Add' ? 'Create' : 'Confirm'}
						</Button>
					</div>
				</>
			)}
		</div>
	);
};
ExpandablePanel.displayName = 'ExpandablePanel';
