import { useState } from 'react';
import { ApiService } from 'legacy/lib/api/HttpService';
import { useQueryClient } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';

export interface IClientPortalSettings {
	[key: string]: boolean | string | string[] | undefined;
	clientPortalEnabled: boolean;
	clientPortalLogoUrl: string;
	clientPortalColorPrimary: string;
	clientPortalColorSecondary: string;
	clientPortalShowProjectBudget: boolean;
	clientPortalAllowSendMessage: boolean;
	clientPortalMessageRecipient: string;
	clientPortalItemsApproval: boolean;
	clientPortalProposalsApproval: boolean;
	clientPortalProposalsRequireSignature: boolean;
}

const api = new ApiService();

export const useClientPortalSettings = () => {
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const updateClientPortalSettings = async (
		params: Partial<IClientPortalSettings>
	) => {
		setIsLoading(true);
		try {
			const response = await api.patchCompany2(params);
			await queryClient.invalidateQueries([ECacheKeys.CompanyTwo]);
			return response;
		} finally {
			setIsLoading(false);
		}
	};

	return { updateClientPortalSettings, isLoading };
};
