import dayjs from 'dayjs';
import { generatePath, Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Button,
	Container,
	Form,
	Row,
	Col,
	Table,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import { FooterFormAction, FormInputAdOns } from '../../../components/Form';
import { addDays } from 'date-fns';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import InputBar from '../../../components/InputBar';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import ListFilter from '../../../components/ListFilter';
import {
	ApiService,
	POSTING_PROCESS_TYPE,
} from '../../../../lib/api/HttpService';
import VendorMiscAddDistributionModal from './VendorMiscAddDistributionModal';
import VendorMiscAddCheckWireModal from './VendorMiscAddCheckWireModal';
import { getCookie } from '../../../../utilities/Auth';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { currencyFormat } from '../../../../helpers/Number';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	getErrorMessage,
} from '../../../../utilities/Response';
import ConfirmModal from '../../../../app/components/modal/ConfirmModal';
import { delay, isEmpty } from 'lodash';
import MSG from '../../../../defaults/Message';
import { navigateOnSuccess } from '../../../../utilities/Router';
import { tableSortingEnableSort } from '../../../../utilities/modules/TableSorting';
import { setDraft } from '../../../../utilities/FormEvent';
import { setLocalStorage } from '../../../../utilities/LocalStorage';
import AsyncVendorsDropdown from '../../../../app/components/dropdowns/AsyncVendorsDropdown';
import { BasicModal } from '../../../../app/components/modal/BasicModal';
import { dateToPayload } from '../../../../helpers/Date';
import SecureDatePicker from '../../../../app/components/security/SecureDatePicker';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

class VendorMiscAdd extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			sinvdt: null,
			defaultAmount: null,
			paydt: null,
			fiscalmonth: null,
			paymentDueDays: 0,
			activeDateIndex: 0,
			data: {},
			dataIsLoaded: false,
			showModal: false,
			showWireModal: false,
			showConfirmModal: false,
			transferType: 'check',
			accounts: [],
			payTypes: [],
			payTypesCC: [],
			distributions: [],
			txnum: null,
			notesHtml: '',
			selectedAccount: null,
			defaults: {
				modal: {},
			},
			totalAmount: 0,
			checks: [],
			showingCheckInfo: false,
			hasExpiredInsurance: false,
			showInsuranceModal: false,
			isSaveNew: false,
		};

		this.api = new ApiService();
		this.userCode = getCookie('dmUsercode');
		this.updatedData = {
			type: 0,
			usercode: this.userCode,
			isWireTransfer: false,
			fiscalmonth: new Date(),
		};
		this.htmlToRtfConverter = new HtmlToRtfBrowser();

		this.txNum = this.props.params.txnum;
		this.isEditing = !isEmpty(this.txNum);
		this.isNew =
			this.props.paths.pathname ===
				generatePath(URI.accountsPayable.vendorInvoice.editNewMisc, {
					txnum: this.props.params.txnum ?? '',
				}) ||
			this.props.paths.pathname === URI.accountsPayable.vendorInvoice.addMisc;

		this.setComponentProp();
	}

	setComponentProp() {
		this.saveSuccessMessage = this.isEditing
			? MSG.success.msg
			: MSG.success.create.APVendorInvMisc;
		this.saveLoadingMessage = this.isEditing
			? MSG.loading.update.msg
			: MSG.loading.create.APVendorInvMisc;
	}

	componentInit() {
		this.title = `${
			this.isEditing ? 'Edit' : 'Add'
		} Expense / Bill / Miscellaneous | Accounts Payable`;
		this.setFormAction(true);
	}

	async componentDidMount() {
		let distributions = [];
		let data = {};
		let sinvdt,
			paydt,
			fiscalmonth = '';
		let totalAmount = 0;
		let selectedVendor = null;

		this.dMloader(true);
		if (this.isNew) {
			if (this.isEditing) {
				const filter = `?$filter=usercode eq ${this.userCode} and txnum eq ${this.txNum}`;
				data = await this.api.getTemporaryVendorInvoices(filter);
				data = {
					...data[0],
					paywith: data[0]?.ccacct ? data[0]?.ccacct : data[0]?.paywith,
				};

				distributions =
					await this.api.getTemporaryVendorInvoiceDetailsOperatingExpense(
						filter
					);
				totalAmount = distributions.reduce(
					(total, item) => total + item.mcost,
					0
				);
				sinvdt = data.sinvdt ? new Date(data.sinvdt) : '';
				paydt = data.paydt ? new Date(data.paydt) : '';
				fiscalmonth = data.fiscalmonth ? new Date(data.fiscalmonth) : '';
				this.updatedData.isWireTransfer =
					data.handcknum?.toLowerCase().startsWith('wt') ||
					data.handcknum === null;
				this.updatedData.handckdt = data.handckdt;

				selectedVendor = await this.api.getVendorByCode(data?.supplier);
			} else {
				try {
					const txnum = await this.api.getTransactionCounterNext('13');
					this.txNum = txnum.newTransactionNumber;
				} catch {
					// Do nothing for now
				}
			}
		} else {
			const filter = `?$filter=txnum eq ${this.txNum}`;
			const invoices = await this.api.getVendorInvoices(filter);
			const [details] = await this.api.getExistingVendorInvoiceDetails(filter);
			data = {
				...invoices[0],
				details,
				paywith: invoices[0]?.ccacct
					? invoices[0]?.ccacct
					: invoices[0]?.paywith,
			};
			sinvdt = data.sinvdt ? new Date(data.sinvdt) : '';
			paydt = data.paydt ? new Date(data.paydt) : '';
			fiscalmonth = data.fiscalmonth ? new Date(data.fiscalmonth) : '';
			selectedVendor = await this.api.getVendorByCode(data?.supplier);
			const txNum = data.txnum;

			await this.api.createTemporaryVendorInvoiceGrid({
				type: 0,
				txNum,
				reviseTxNum: Number(this.txNum),
			});

			distributions =
				await this.api.getTemporaryVendorInvoiceDetailsOperatingExpense(
					`?$filter=usercode eq ${this.userCode} and txnum eq ${this.txNum}`
				);
			totalAmount = distributions.reduce(
				(total, item) => total + item.mcost,
				0
			);

			if (data.checkInfo?.length) {
				this.setState({
					showingCheckInfo: true,
				});
			}
		}

		let payTypes = await this.api.getPayTypes();
		let accounts = await this.api.getSummaryGLAccounts(
			'?$filter=specacct eq 2'
		);

		let payTypesCC = [];
		accounts = accounts.map((a) => {
			payTypesCC.push(a.key);
			return { value: a.key, label: `${a.value} (${a.key})` };
		});

		payTypes = payTypes.map((p) => {
			return {
				value: p.defAcct,
				// label: p.payType,
				id: p.id,
				label: `${p.payType} (${p.defAcct})`,
				isCheckWire: p.specAcct === 1,
			};
		});
		payTypes = [...payTypes, ...accounts];

		const params = new URLSearchParams(window.location.search);

		const transactionId = params.get('transactionId');

		let defaultVendor;
		let defaultSinvdt;
		let defaultPaydt;
		let defaultPayWith;
		let modalDefaults = {};
		let defaultAmount = null;
		let wireTransferNumber = null;

		if (transactionId) {
			let defaultCheckWireAccount = null;

			const defaults = await this.api.get(
				`Transactions/${transactionId}/invoice/defaults`
			);
			if (defaults.vendorId) {
				defaultVendor = await this.api.getAllVendorsSummaries(
					`?$filter=id eq ${defaults.vendorId}`
				);
			}

			if (defaults.payWith || defaults.payTypeAccountId) {
				defaultPayWith = payTypes.find((p) =>
					!defaults.payTypeAccountId
						? p.value === defaults.payWith
						: p.id === defaults.payTypeAccountId
				);
			}

			const glAccounts = await this.api.getSummaryGLAccounts(
				'?$filter=specacct eq 1'
			);

			const mappedGlAccounts = glAccounts.map((i) => {
				return { label: `${i.value} (${i.key})`, value: i.key };
			});

			defaultCheckWireAccount = mappedGlAccounts.find(
				(acc) => acc.value === defaultPayWith?.value
			)?.value;

			if (defaultCheckWireAccount && !defaults.checkNumber) {
				const number = await this.api.getWireTransferNumberForAccountNumber(
					defaultCheckWireAccount
				);
				wireTransferNumber = number.wireTransferNumber;
			}

			if (defaults.transactionDate) {
				defaultPaydt = new Date(defaults.transactionDate);
				defaultSinvdt = new Date(defaults.transactionDate);
			}
			if (defaults.amount) {
				defaultAmount = defaults.amount;
			}

			const transactionName = defaults.transactionName
				? defaults.transactionName.substring(0, 30)
				: '';

			data = {
				...data,
				isWireTransfer:
					defaults.checkNumber === null && defaultCheckWireAccount
						? true
						: false,
				txdesc: transactionName,
				paywith: defaultPayWith?.value || payTypes[0].value,
				supplier: defaultVendor?.length ? defaultVendor[0].key : null,
				sinvdt: defaultSinvdt ?? null,
				paydt: defaultPaydt ?? null,
				handcknum: defaults.checkNumber || wireTransferNumber,
				handckdt: new Date(defaults.transactionDate),
				handcktxdesc: transactionName,
				handckcashacct: defaultCheckWireAccount,
			};

			modalDefaults = {
				isWireTransfer:
					defaults.checkNumber === null && defaultCheckWireAccount
						? true
						: false,
				handcknum: defaults.checkNumber || wireTransferNumber,
				handckdt: new Date(defaults.transactionDate),
				handcktxdesc: transactionName,
				handckcashacct: defaultCheckWireAccount,
			};

			this.updatedData = {
				...this.updatedData,
				...data,
			};
		}

		let defaultAccount = null;

		if (defaultVendor?.length) {
			const vendor = await this.api.getVendorByCode(defaultVendor);
			defaultAccount = { account: vendor?.acct };
		}

		selectedVendor = defaultVendor?.length
			? {
					label: `${
						defaultVendor[0].value ? `${defaultVendor[0].value} ` : ''
					}[${defaultVendor[0].key}]`,
					value: defaultVendor[0].key,
			  }
			: selectedVendor
			? {
					label: `${selectedVendor?.vendorn} [${selectedVendor?.vendor}]`,
					value: selectedVendor?.vendor,
					name: selectedVendor?.vendorn,
					id: selectedVendor?.id,
			  }
			: null;

		this.setState(
			{
				selectedVendor,
				payTypes,
				defaultAmount: defaultAmount || null,
				payTypesCC,
				dataIsLoaded: true,
				data,
				distributions,
				sinvdt: defaultSinvdt || sinvdt,
				paydt: defaultPaydt || paydt,
				fiscalmonth,
				notesHtml: await mapRtfToDraft(data?.notesrtf ?? ''),
				totalAmount,
				defaults: {
					modal: modalDefaults,
				},
				selectedAccount: defaultAccount,
			},
			async () => {
				if (!transactionId && !this.state.data.paywith) {
					this.setState({
						data: {
							...this.state.data,
							paywith: this.state.payTypes[0].value,
						},
					});
				}
				if (defaultVendor?.length && selectedVendor) {
					const hasExpiredInsurance = await this.vendorExpiredInsurance(
						selectedVendor.value
					);
					this.setState({
						hasExpiredInsurance,
					});
					await this.getPaymentDaysOfSupplier(selectedVendor);
				}
				this.enableTableSort(distributions);
			}
		);

		this.dMloader(false);
	}

	async getPaymentDaysOfSupplier(data) {
		const vendor = data?.value ? data?.value : this.state.data.supplier;
		const vendorData = await this.api.getVendorByCode(vendor);

		const paymentDueDays = Number(vendorData?.paydays || 0);

		if (this.state.sinvdt) {
			const paydt = addDays(this.state.sinvdt, paymentDueDays);
			this.setState({ paydt }, () => {
				this.updatedData['paydt'] = paydt;
			});
		}

		this.setState({
			paymentDueDays,
			defaultAccount: { account: vendorData?.acct },
		});
	}

	handleDateChange = (name, date) => {
		const data = this.state.data;
		if (name === 'sinvdt') {
			if (this.state.paymentDueDays === 0) {
				this.updatedData['paydt'] = date;
				this.setState({
					paydt: date,
				});
				data.paydt = date;
			} else {
				const paydt = addDays(date, this.state.paymentDueDays);
				this.updatedData['paydt'] = paydt;
				this.setState({
					paydt: paydt,
				});
				data.paydt = paydt;
			}
		}

		this.updatedData[name] = date;
		this.setState({
			data: {
				...data,
				[name]: date,
			},
			[name]: date,
		});
	};

	handleAddAccount = (data) => {
		if (this.state.payTypesCC.includes(data.account)) {
			this.handleChange(
				{
					value: data.account,
					label: data.account,
				},
				{ name: 'ccacct' }
			);
		}
	};

	handleChange = async (e, meta = {}) => {
		let key, value;
		let { data } = this.state;
		const params = new URLSearchParams(window.location.search);
		const transactionId = params.get('transactionId');
		const shouldSetDefaultsForWireTransfer =
			transactionId && meta?.name === 'paywith';

		if (e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.updatedData[key] = value;
		data[key] = value;

		if (shouldSetDefaultsForWireTransfer) {
			this.updatedData = {
				...this.updatedData,
				...this.state.defaults.modal,
			};
		}
		this.setState(
			shouldSetDefaultsForWireTransfer
				? {
						data: {
							...data,
							...this.state.defaults.modal,
						},
				  }
				: {
						data,
				  }
		);

		if (meta?.name === 'supplier') {
			const hasExpiredInsurance = await this.vendorExpiredInsurance(e?.value);
			this.getPaymentDaysOfSupplier(e);
			this.setState({
				selectedVendor: e,
				hasExpiredInsurance,
			});
		}
	};

	handleWysiwygChange = (e) => {
		this.updatedData['notes'] = e.target.value;
		this.updatedData['notesrtf'] = this.htmlToRtfConverter.convertHtmlToRtf(
			e.target.html
		);
	};

	handleWireChanges = (wireData) => {
		this.handleDataUpdate(wireData);
		this.hideWireModal();
	};

	handleDataUpdate = (wireData) => {
		this.updatedData = { ...this.updatedData, ...wireData };
	};

	handleSelectAllChecks = () => {
		let checks = [];
		if (this.state.checks.length !== this.state.distributions.length) {
			checks = this.state.distributions.map((item, i) => {
				return i;
			});
		}

		this.setState({
			checks: checks,
		});
	};

	handleCheck = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);

		this.setState({
			checks: checks,
		});
	};

	toggleModal = (e) => {
		this.setState({
			showConfirmModal: !this.state.showConfirmModal,
		});
	};

	openConfirmDeletion = (e) => {
		this.setState({
			showConfirmModal: true,
		});
	};

	handleDelete = async (e) => {
		e.preventDefault();

		setDraft(true);

		const deletedData = this.state.distributions.filter((val, idx) =>
			this.state.checks.includes(idx)
		);

		let errorIds = [];
		for (const data of deletedData) {
			const postData = {
				usercode: data.usercode,
				txnum: data.txnum,
				seqnum: data.seqnum,
			};

			await this.api
				.deleteTemporaryVendorInvoiceDetailsOperatingExpense(postData)
				.catch((error) => {
					errorIds.push(id);
					if (error.response === undefined) {
						displayAlert('danger', error.message);
					} else {
						displayAlert('danger', error.response.data.userError);
					}
				});
		}

		if (!errorIds.length) {
			displayAlert('success', 'The data was succesfully deleted');
		}

		this.refreshDetailList();
		this.setState({
			checks: [],
			showConfirmModal: false,
		});
	};

	async refreshDetailList() {
		const filter = `?$filter=usercode eq ${this.userCode} and txnum eq ${this.txNum}`;
		const distributions =
			await this.api.getTemporaryVendorInvoiceDetailsOperatingExpense(filter);
		const totalAmount = distributions.reduce(
			(total, item) => total + item.mcost,
			0
		);

		this.setState(
			{
				showModal: false,
				distributions,
				totalAmount,
			},
			() => {
				this.enableTableSort(distributions);
			}
		);
	}

	showModal = (e, item = null) => {
		e.preventDefault();
		this.setState({
			showModal: true,
			selectedAccount: item,
		});
	};

	hideModal = async (refreshData = false) => {
		if (refreshData) {
			setTimeout(() => {
				this.refreshDetailList();
			}, 1000);
		} else {
			this.setState({
				showModal: false,
			});
		}
	};

	showWireModal = (e) => {
		this.setState({
			showWireModal: true,
		});
	};

	hideWireModal = (e) => {
		this.setState({
			showWireModal: false,
		});
	};

	handleSave = async (isNew) => {
		const params = new URLSearchParams(window.location.search);
		const transactionId = params.get('transactionId');
		const spaywith = this.getSelectedPayWith();
		this.updatedData.txnum = this.txNum;
		this.updatedData.proj = null;
		this.updatedData.ponum = null;

		this.updatedData.transactionId = transactionId
			? Number(transactionId)
			: null;

		this.updatedData.fiscalmonth = dateToPayload(
			dayjs(this.updatedData.fiscalmonth)
				.startOf('month')
				.hour(0)
				.minute(0)
				.second(0)
				.toDate()
		);
		this.updatedData.paywith = spaywith?.label ?? this.updatedData.paywith;
		this.updatedData.sinvdt = dateToPayload(this.state.data.sinvdt);
		this.updatedData.paydt = dateToPayload(this.state.data.paydt);
		this.updatedData.handckdt = dateToPayload(this.updatedData.handckdt);

		const isCheckWire = this.isCheckWire();

		const createPayload = {
			...this.updatedData,
			ccacct: spaywith?.value,
		};

		if (!isCheckWire) {
			delete createPayload.handcknum;
			delete createPayload.handcktxdesc;
			delete createPayload.handckdt;
			delete createPayload.handckcashacct;
			createPayload.isWireTransfer = false;
		}

		if (isCheckWire) {
			delete createPayload.ccacct;
		}

		const listUrl = this.isNew
			? URI.accountsPayable.vendorInvoice.listNew
			: URI.accountsPayable.vendorInvoice.listExisting;
		try {
			displayAlertLoader(this.saveLoadingMessage);
			if (this.isNew) {
				if (this.isEditing) {
					await this.api.updateTemporaryVendorInvoice(this.updatedData);
				} else {
					await this.api.createTemporaryVendorInvoice(createPayload);
				}
			} else {
				this.updatedData.revisestxnum = this.txNum;
				this.updatedData.txnum = this.txNum;
				this.updatedData.supplier = this.state.data.supplier;
				this.updatedData.sinvnum = this.state.data.sinvnum;
				this.updatedData.txdesc = this.state.data.txdesc;

				const payload = {
					...this.updatedData,
					amount: this.state.totalAmount,
					ccacct: spaywith?.value,
				};

				if (!isCheckWire) {
					delete payload.handcknum;
					delete payload.handcktxdesc;
					delete payload.handckdt;
					delete payload.handckcashacct;
					createPayload.isWireTransfer = false;
				}

				if (isCheckWire) {
					delete payload.ccacct;
				}

				await this.api.createTemporaryVendorInvoice(payload);
				await this.api.postVendorInvoiceFiscalMonth({
					processType: POSTING_PROCESS_TYPE.REVISE_TRANSACTION,
					txNum: this.txNum,
					fiscalmonth: this.state.fiscalmonth,
				});
			}

			displayAlertSuccess(this.saveSuccessMessage);

			setLocalStorage('isDraft', 'false');

			const _delay = isNew ? 1200 : 700;
			delay(() => {
				navigateOnSuccess(this, isNew, false, listUrl);
			}, _delay);
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}
	};

	enableTableSort(data) {
		tableSortingEnableSort({
			data,
			stateKey: 'distributions',
			classRef: this,
			targetTable: '.a-table-heading.invoice',
		});
	}

	componentWillUnmount() {
		this.dMloader(false);
	}

	getSelectedPayWith() {
		return this.state.payTypes.find((i) => {
			return (
				i.value === this.state.data?.paywith ||
				this.state.data?.paywith === i.label
			);
		});
	}

	isCheckWire() {
		return this.isNew ? this.getSelectedPayWith()?.isCheckWire === true : false;
	}

	renderHeaderTitle() {
		return `${
			this.isEditing ? 'Edit' : 'Add'
		} Expense / Bill / Miscellaneous | Accounts Payable`;
	}

	async vendorExpiredInsurance(vendor) {
		const insuranceFilter = `?$filter=vendor eq '${vendor}'`;

		return await this.api?.getInsurances(insuranceFilter)?.then((insurances) =>
			insurances?.some((i) => {
				const hasExpired = i.expirationdt
					? new Date(i.expirationdt) < new Date()
					: false;

				return i.active && hasExpired;
			})
		);
	}

	showConfirmationModal(isNew) {
		this.setState({
			showInsuranceModal: true,
			isSaveNew: isNew,
		});
	}

	handleCloseModal() {
		this.setState({
			showInsuranceModal: false,
		});
	}

	handleConfirmSave() {
		this.handleSave(this.state.isSaveNew);
		this.handleCloseModal();
	}

	renderHeader() {
		const listURL = this.isNew
			? URI.accountsPayable.vendorInvoice.listNew
			: URI.accountsPayable.vendorInvoice.listExisting;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={listURL}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: listURL,
							}}
							linkAs={Link}
						>
							Accounts Payable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: listURL,
							}}
							linkAs={Link}
						>
							Vendor Deposits & Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item>{this.renderHeaderTitle()}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.renderHeaderTitle()}</HeaderLight.Title>

					<div>
						<Button
							as={Link}
							variant="ivory"
							name="cancel"
							className=""
							to={listURL}
						>
							Cancel
						</Button>
						{/* Submit Button */}
						{!this.isEditing && (
							<Button
								variant="primary ms-2"
								onClick={() => {
									return this.state?.hasExpiredInsurance
										? this.showConfirmationModal(true)
										: this.handleSave(true);
								}}
							>
								Save & New
							</Button>
						)}

						<Button
							variant="primary ms-2"
							onClick={() => {
								return this.state?.hasExpiredInsurance
									? this.showConfirmationModal(false)
									: this.handleSave(false);
							}}
						>
							Save
						</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height">
					<Container fluid>{this.renderContent()}</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button
						variant="primary"
						size="lg"
						onClick={() => {
							return this.state?.hasExpiredInsurance
								? this.showConfirmationModal(false)
								: this.handleSave(false);
						}}
					>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}

	renderInputBar() {
		return (
			<InputBar className="full multiple">
				<InputBar.Links>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Amount
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control
								type="text"
								placeholder="0.00"
								size="sm"
								value={this.state.totalAmount.toFixed(2)}
								readOnly
							/>
						</FormInputAdOns>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		);
	}

	renderListFilter() {
		return (
			<ListFilter expandable={0}>
				<ListFilter.Actions
					sm={12}
					className="ms-auto"
					replaceclassmatch="justify-content-xl-end"
					replaceclassmatchwith="justify-content-end"
				>
					<ListFilter.Action>
						<Button
							as={Link}
							onClick={this.showModal}
							variant="primary"
							size="sm"
							className="btn-icon ms-3"
						>
							<i className="ri-add-fill"></i> Add
						</Button>
						<Button
							to="#"
							variant="ivory"
							size="sm"
							className={`btn-icon btn-action ms-3 ${
								this.state.checks.length === 0 ? 'disabled' : ''
							}`}
							disabled={this.state.checks.length === 0}
							onClick={this.openConfirmDeletion}
						>
							<i className="ri-close-line"></i> Delete
						</Button>
					</ListFilter.Action>
				</ListFilter.Actions>
			</ListFilter>
		);
	}

	renderContent() {
		const DatePickerComponent = this.isNew ? DatePicker : SecureDatePicker;

		return (
			<>
				<Form.Group>
					<Row xs={1} lg={2} className="py-4">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor</Form.Label>
								</Col>
								<Col>
									<AsyncVendorsDropdown
										useAllSummaries
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
											}),
										}}
										// options={this.state.vendors}
										className="react-select"
										placeholder="Select vendor"
										name="supplier"
										// value={this.state.vendors.filter(
										//     (i) => {
										//         return (
										//             i.value ===
										//             this.state.data?.supplier
										//         )
										//     }
										// )}
										defaultValue={this.state.selectedVendor}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Invoice Date</Form.Label>
								</Col>
								<Col lg={3}>
									<div className="react-select-header">
										<DatePicker
											selected={this.state.sinvdt}
											showMonthDropdown="true"
											showYearDropdown="true"
											className="form-control"
											placeholderText="Select"
											name="sinvdt"
											onChange={this.handleDateChange.bind(this, 'sinvdt')}
											autoComplete={'off'}
										/>
									</div>
								</Col>

								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Due Date</Form.Label>
								</Col>
								<Col lg={3}>
									<div className="react-select-header">
										<DatePicker
											selected={this.state.paydt}
											showMonthDropdown="true"
											showYearDropdown="true"
											className="form-control"
											placeholderText="Select"
											name="paydt"
											onChange={this.handleDateChange.bind(this, 'paydt')}
											autoComplete={'off'}
										/>
									</div>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Pay With</Form.Label>
								</Col>
								<Col lg={6}>
									<Select
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
											}),
										}}
										options={this.state.payTypes}
										className="react-select"
										placeholder="Please select"
										name="paywith"
										value={this.getSelectedPayWith()}
										onChange={this.handleChange}
									/>
								</Col>
								<Col>
									<Button
										onClick={this.showWireModal}
										variant="primary"
										size="sm"
										className="btn-icon"
										style={{ fontSize: '11px' }}
										disabled={!this.isCheckWire()}
									>
										{this.state.data?.handcknum
											? `Edit Ck/WT # ${this.state.data?.handcknum}`
											: 'Enter Hand Check / Wire Details'}
									</Button>
								</Col>
							</Row>

							{!this.isNew && (
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Fiscal Month</Form.Label>
									</Col>
									<Col lg={3}>
										<div className="react-select-header">
											<DatePickerComponent
												selected={this.state.fiscalmonth}
												onChange={this.handleDateChange.bind(
													this,
													'fiscalmonth'
												)}
												className="form-control"
												placeholderText="Please select"
												showMonthYearPicker
												dateFormat="MM/yyyy"
												name="fiscalmonth"
											/>
										</div>
									</Col>
								</Row>
							)}
						</Col>

						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Invoice Number</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										name="sinvnum"
										defaultValue={this.state.data?.sinvnum}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Trans. Desc.</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										name="txdesc"
										defaultValue={this.state.data?.txdesc}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row className="py-2">
						<Col
							sm={12}
							className="mb-3 mb-lg-0 position-relative table-action-bar"
						>
							{this.renderListFilter()}

							<div className="table-gradient">
								<Table striped responsive className="a-table">
									<thead>
										<tr key="0" className="a-table-heading invoice">
											<th align="middle" className="w-70px">
												<div className="d-flex justify-content-center">
													<Form.Check
														inline
														label=""
														name={`inline-check-th-0`}
														type="checkbox"
														data-id={`th-0`}
														id={`inline-check-th-0`}
														checked={this.state.checks.length > 0}
														className={
															this.state.checks.length > 0 &&
															this.state.checks.length <
																this.state.distributions.length
																? 'line'
																: ''
														}
														onClick={this.handleSelectAllChecks}
													/>
												</div>
											</th>
											<th>
												<span className="sort" data-field="account">
													Acct. No.
												</span>
											</th>
											<th>
												<span className="sort" data-field="accountn">
													Account Name
												</span>
											</th>
											<th>
												<span className="sort" data-field="mcost">
													Amount
												</span>
											</th>
											<th>
												<span className="sort" data-field="proj">
													Project Code
												</span>
											</th>
											<th>
												<span className="sort" data-field="descOnly">
													Description
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{this.state.distributions.map((item, i) => (
											<tr
												key={i}
												className={this.state.checks[i] ? `active` : ''}
											>
												<td>
													<div className="d-flex justify-content-center">
														<Form.Check
															label=""
															type="checkbox"
															checked={this.state.checks.includes(i)}
															onChange={() => {
																this.handleCheck(i);
															}}
														/>
													</div>
												</td>
												<td>
													<Form.Check
														inline
														label=""
														name={`group-` + i}
														type="checkbox"
														data-id={i}
														id={`inline-radio-` + i}
														onChange={this.handleChecks}
														className={'d-none'}
													/>
													<Link
														className="text-black"
														onClick={(e) => this.showModal(e, item)}
													>
														{item.account}
													</Link>
												</td>
												<td>{item.accountn}</td>
												<td>{currencyFormat(item.mcost, '$')}</td>
												<td>{item.proj}</td>
												<td>{item.miscdesc}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</Col>
						<Col sm={12}>{this.renderInputBar()}</Col>
					</Row>

					<Row xs={1} lg={2} className="py-4">
						<Col className="mb-3 mb-lg-0">
							<Row>
								<Col lg={10}>
									<Row xs={1} lg={2}>
										<Col className="mb-3 mb-lg-0">
											<Row>
												<Col xs={12} className="mb-2">
													<Form.Label className="mb-0">Discount</Form.Label>
												</Col>
												<Col xs={12}>
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="discount"
														defaultValue={this.state.data?.discount}
														onChange={this.handleChange}
													/>
												</Col>
											</Row>
										</Col>
										<Col className="mb-3 mb-lg-0">
											<Row>
												<Col xs={12} className="mb-2">
													<Form.Label className="mb-0">Days to Take</Form.Label>
												</Col>
												<Col xs={12}>
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="discdays"
														defaultValue={this.state.data?.discdays}
														onChange={this.handleChange}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col className="mb-3 mb-lg-0">
							<Row xs={12} lg={2}>
								<Col lg={{ span: 10, offset: 2 }}>
									<Row>
										<Col xs={12} className="mb-2">
											<Form.Label className="mb-0">Notes</Form.Label>
										</Col>
										<Col xs={12}>
											<WysiwygEditor onChange={this.handleWysiwygChange}>
												{this.state.notesHtml}
											</WysiwygEditor>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form.Group>

				{this.state.showModal && (
					<VendorMiscAddDistributionModal
						defaultAmount={this.state.defaultAmount}
						hideModal={this.hideModal.bind(this)}
						txnum={this.txNum}
						userCode={this.userCode}
						account={this.state.selectedAccount ?? this.state.defaultAccount}
						listTotalAmount={this.state.totalAmount}
						onAddAccount={this.handleAddAccount}
						isNew={this.isNew}
					/>
				)}

				{this.state.showWireModal && (
					<VendorMiscAddCheckWireModal
						hideModal={this.hideWireModal.bind(this)}
						data={this.state.data}
						updatedData={this.updatedData}
						onWireOkay={this.handleWireChanges}
						onDataUpdate={this.handleDataUpdate}
					/>
				)}

				<ConfirmModal
					refreshData={''}
					show={this.state.showConfirmModal}
					toggleModal={this.toggleModal}
					confirmAction={this.handleDelete.bind(this)}
				/>

				{this.state.showingCheckInfo && (
					<BasicModal
						message={`This invoice cannot be edited because it has a payment associated with it: ${this.state.data?.checkInfo}`}
						onPrimary={() => {
							this.props.navigate(
								URI.accountsPayable.vendorInvoice.listExisting
							);
						}}
						hasSecondary={false}
					/>
				)}

				<ConfirmationModal
					confirmAction={this.handleConfirmSave.bind(this)}
					show={this.state.showInsuranceModal}
					toggleModal={this.handleCloseModal.bind(this)}
					labelCancel="Cancel"
					labelOK="Ok"
					message="WARNING: the vendor has an active Insurance Policy that has expired."
					title="Vendor deposit, Invoice or Operating Expense"
				/>
			</>
		);
	}
}

export default WithRouter(VendorMiscAdd);
