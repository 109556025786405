import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ClientPortalFeatureToggles = () => {
	const {
		register,
		watch,
		formState: { errors },
		trigger,
		setValue,
		clearErrors,
	} = useFormContext();
	const clientPortalAllowSendMessage = watch('clientPortalAllowSendMessage');
	const clientPortalProposalsApproval = watch('clientPortalProposalsApproval');
	const [inputEmail, setInputEmail] = useState('');

	const version2 = false;

	const clientPortalMessageRecipient =
		watch('clientPortalMessageRecipient') || '';

	const validateEmail = (email: string) => {
		return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
	};

	const addEmail = (email: string) => {
		if (validateEmail(email)) {
			const currentEmails = clientPortalMessageRecipient
				.split(',')
				.filter(Boolean);
			const newEmails = [...currentEmails, email];
			setValue('clientPortalMessageRecipient', newEmails.join(','), {
				shouldDirty: true,
			});
			setInputEmail('');
			clearErrorIfValidEmail();
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			addEmail(inputEmail);
		}
	};

	const handleBlur = () => {
		if (inputEmail) {
			addEmail(inputEmail);
		}
	};

	const removeEmail = (emailToRemove: string) => {
		const newEmails = clientPortalMessageRecipient
			.split(',')
			.filter((email: string) => email !== emailToRemove);
		setValue('clientPortalMessageRecipient', newEmails.join(','), {
			shouldDirty: true,
		});
		trigger('clientPortalMessageRecipient');
	};

	const clearErrorIfValidEmail = () => {
		if (validateEmail(inputEmail)) {
			trigger('clientPortalMessageRecipient');
			clearErrors('clientPortalMessageRecipient');
		}
	};

	useEffect(() => {
		if (!clientPortalAllowSendMessage) {
			setValue('clientPortalMessageRecipient', '', { shouldDirty: true });
		}
	}, [clientPortalAllowSendMessage, setValue]);

	useEffect(() => {
		if (!clientPortalProposalsApproval) {
			setValue('clientPortalProposalsRequireSignature', false, {
				shouldDirty: true,
			});
		}
	}, [clientPortalProposalsApproval, setValue]);

	return (
		<div className="tw-flex tw-flex-col tw-gap-4">
			<Form.Label className="tw-font-semibold">
				Project Default Settings
			</Form.Label>
			<Row>
				<div className="tw-mb-4 tw-ml-4 tw-w-full">
					<Form.Group className="tw-mb-3">
						<Form.Check
							type="checkbox"
							id="clientPortalShowProjectBudget"
							label="Show Project Budget"
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...register('clientPortalShowProjectBudget')}
						/>
					</Form.Group>

					{version2 && (
						<Form.Group className="tw-mb-3">
							<Form.Check
								type="checkbox"
								id="clientPortalAllowSendMessage"
								label="Allow Send Message"
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...register('clientPortalAllowSendMessage', {
									onChange: () => trigger('clientPortalMessageRecipient'),
								})}
							/>
						</Form.Group>
					)}

					{version2 && clientPortalAllowSendMessage && (
						<Form.Group className="tw-mb-3 tw-ms-4">
							<Form.Label>Send Message To:</Form.Label>
							<Form.Control
								type="email"
								placeholder="email@example.com"
								value={inputEmail}
								onChange={(event) => setInputEmail(event.target.value)}
								onKeyDown={handleKeyDown}
								onBlur={handleBlur}
								isInvalid={!!errors.clientPortalMessageRecipient}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.clientPortalMessageRecipient?.message &&
									typeof errors.clientPortalMessageRecipient?.message ===
										'string' &&
									errors.clientPortalMessageRecipient?.message}
							</Form.Control.Feedback>
							<Form.Text className="text-muted">
								Press Enter or leave the field to add an email
							</Form.Text>
							<div className="tw-mt-2">
								{clientPortalMessageRecipient
									.split(',')
									.filter(Boolean)
									.map((email: string) => (
										<span
											key={email}
											className="tw-bg-gray-200 tw-rounded-full tw-p-2 tw-mr-2 tw-inline-flex tw-items-center"
										>
											{email}
											<button
												type="button"
												onClick={() => removeEmail(email)}
												className="tw-ml-2 tw-text-red-500 tw-font-bold"
											>
												×
											</button>
										</span>
									))}
							</div>
						</Form.Group>
					)}

					<Form.Group className="tw-mb-3">
						<Form.Check
							type="checkbox"
							id="clientPortalItemsApproval"
							label="Item Approval"
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...register('clientPortalItemsApproval')}
						/>
					</Form.Group>

					<Form.Group className="tw-mb-3">
						<Form.Check
							type="checkbox"
							id="clientPortalProposalsApproval"
							label="Approvals on Proposals"
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...register('clientPortalProposalsApproval')}
						/>
					</Form.Group>

					{clientPortalProposalsApproval && (
						<Form.Group className="tw-mb-3 tw-ms-4">
							<Form.Check
								type="checkbox"
								id="clientPortalProposalsRequireSignature"
								label="Require Signature"
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...register('clientPortalProposalsRequireSignature')}
							/>
						</Form.Group>
					)}
				</div>
			</Row>
		</div>
	);
};

ClientPortalFeatureToggles.displayName = 'ClientPortalFeatureToggles';

export { ClientPortalFeatureToggles };
