import { useMutation } from '@tanstack/react-query';
import {
	deleteReconciliations,
	type IDeleteReconciliationsParams,
} from 'api/resources/reconciliations';

export const usePostReconciliationsDelete = () => {
	return useMutation((params: IDeleteReconciliationsParams) =>
		deleteReconciliations(params)
	);
};
