import { useQuery } from '@tanstack/react-query';
import { getCompany } from 'api/resources/company';
import type { Company as TCompany } from 'legacy/lib/api/types/Company';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetCompany = () => {
	const query = useQuery<TCompany>({
		queryKey: [ECacheKeys.Company],
		queryFn: getCompany,
		refetchInterval: false,
		refetchOnWindowFocus: false,
		staleTime: 0,
		cacheTime: 0,
	});

	return query;
};
