import { ApiService } from 'legacy/lib/api/HttpService';

const api = new ApiService();

export interface IClientPortalProjectToken {
	token: string;
	isOk: boolean;
	resourceNotAuthorized: boolean;
	resourceInvalidPayload: boolean;
	resourceForbidden: boolean;
	userError: string | null;
	internalError: string | null;
	errorCode: string | null;
}

export const postClientPortalToken = async (
	projectId: number
): Promise<IClientPortalProjectToken> => {
	const response = await api.postClientPortalToken(projectId);
	return response;
};
