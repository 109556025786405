import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { useSession } from 'legacy/app/context/session';
import { useCheckbookReport } from '../hooks/useCheckbookReport';

const CheckbookReconcileReport = () => {
	const { downloadCheckbookReport } = useCheckbookReport();
	const { usercode } = useSession();

	const { checkbook, totals, showPrintModal, closePrintModal } =
		useCheckbookReconcile();
	const [showCleared, setShowCleared] = useState(false);

	const onPrintConfirm = async () => {
		if (!checkbook || !totals) {
			return;
		}

		const value = [
			checkbook.cashAccount,
			checkbook.statementDate,
			checkbook.endingBalance,
			totals.balance,
			totals.computerBalance,
			showCleared,
			usercode,
		].join(',');

		await downloadCheckbookReport('reconcile', value);
	};

	return (
		<ConfirmationModal
			confirmAction={onPrintConfirm}
			show={showPrintModal}
			toggleModal={closePrintModal}
			title="Checkbook Reconciliation Report"
			okBtnStyle="primary"
			labelOK="Ok"
			labelCancel="Cancel"
		>
			<div className="tw-grid tw-place-content-center">
				<Form.Check
					type="checkbox"
					id="show-cleared"
					label="Show Cleared Entries?"
					className="rounded"
					onChange={(e) => setShowCleared(e.target.checked)}
				/>
			</div>
		</ConfirmationModal>
	);
};

CheckbookReconcileReport.displayName = 'CheckbookReconcileReport';

export { CheckbookReconcileReport };
