import React from 'react';
import URI from 'legacy/defaults/RoutesDefault';
import { useHeader } from 'legacy/hooks/useHeader';
import {
	SECURITY_ATTRIBUTE_TYPES,
	useHasAccess,
} from 'legacy/app/context/security';
import { Button } from 'react-bootstrap';

export const CheckbookHeader = () => {
	const hasReconcileAccess = useHasAccess(
		60,
		SECURITY_ATTRIBUTE_TYPES.DenyAccess
	);

	const actions = hasReconcileAccess
		? [
				<Button
					key="checkbook-reconcile-button"
					variant="light"
					className="text-secondary-green"
					size="lg"
					href={URI.accountsPayable.checkbook.reconcile}
				>
					Reconcile
				</Button>,
		  ]
		: [];

	useHeader({
		title: 'Checkbook',
		breadcrumbs: [
			{
				text: 'Accounts Payable',
				to: URI.accountsPayable.creditCard.list,
			},
			{
				text: 'Checkbook',
			},
		],
		enableBackButton: false,
		actions,
	});
	return <></>;
};

CheckbookHeader.displayName = 'CheckbookHeader';
