import React from 'react';
import { Breadcrumb, Button, NavLink } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import URI from 'legacy/defaults/RoutesDefault';
import { HeaderLight } from 'legacy/templates/components/Header';

type TIndividualAccountHeaderProps = {
	accountName: string;
	onSave: () => void;
	disableSaveButton: boolean;
};

export const IndividualAccountHeader = ({
	accountName,
	onSave,
	disableSaveButton,
}: TIndividualAccountHeaderProps) => {
	const navigate = useNavigate();
	return (
		<HeaderLight>
			<HeaderLight.Breadcumbs>
				<NavLink
					onClick={() => navigate(URI.accountSettings.plaidAccounts)}
					className="!tw-flex !tw-mr-6 !tw-items-center !tw-font-bold !tw-text-[#006A53]"
				>
					<i className="ri-arrow-left-s-line"></i> Back
				</NavLink>
				<Breadcrumb>
					<Breadcrumb.Item
						linkProps={{ to: URI.accountSettings.plaidAccounts }}
						linkAs={Link}
					>
						Bank Connect
					</Breadcrumb.Item>
					<Breadcrumb.Item>{accountName}</Breadcrumb.Item>
				</Breadcrumb>
			</HeaderLight.Breadcumbs>
			<HeaderLight.Content>
				<HeaderLight.Title>{accountName}</HeaderLight.Title>
				<Button
					type="submit"
					disabled={disableSaveButton}
					onClick={onSave}
					variant="primary"
				>
					Save
				</Button>
			</HeaderLight.Content>
		</HeaderLight>
	);
};

IndividualAccountHeader.displayName = 'IndividualAccountHeader';
