import React from 'react';
import { useGetAccountName } from 'legacy/pages/accounts-payable/credit-card/reconcile/hooks';
import {
	formatDate,
	formatMoney,
	searchData,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/utils';
import { EAccountType } from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { TReconciliation } from 'legacy/lib/api/HttpService';
import { UseQueryResult } from '@tanstack/react-query';
import {
	filterAccounts,
	flagLatest,
	sortData,
} from 'legacy/templates/modules/accounts-payable/checkbook/CheckbookHistory/CheckbookHistoryUtils';
import { useCheckbookHistory } from '../hooks/useCheckbookHistory';
import dayjs from 'dayjs';
import { useCheckbookReport } from '../../hooks/useCheckbookReport';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';

type TCheckbookHistoryTableBodyProps = {
	query: UseQueryResult<TReconciliation[], unknown>;
	searches: TReconciliation;
	selectedAccount: string;
	sort: unknown[];
};

export const CheckbookHistoryTableBody = ({
	query,
	searches,
	selectedAccount,
	sort,
}: TCheckbookHistoryTableBodyProps) => {
	const { handleOnDelete } = useCheckbookHistory();
	const { downloadCheckbookReport } = useCheckbookReport();
	const { isLoading, data } = query;

	const getAccountName = useGetAccountName(EAccountType.ALL);

	const search = (data: TReconciliation[]): TReconciliation[] => {
		return Object.keys(searches).reduce(
			(prev: TReconciliation[], cur: string): TReconciliation[] => {
				const needle = searches[cur as keyof TReconciliation];
				return searchData(needle, cur, prev);
			},
			filterAccounts(selectedAccount, data)
		);
	};

	const [sortField, sortDirection] = sort;

	const handleOnReportDownload = async (
		cashAccount: string,
		statementDate: string
	) => {
		const value = [
			cashAccount,
			dayjs(new Date(statementDate)).format('YYYY-MM-DD'),
		].join(',');

		await downloadCheckbookReport('history', value);
	};

	return (
		<tbody>
			{isLoading ? (
				<>
					<tr className="tr-loading">
						<td className="td-1" colSpan={6}>
							<span>&nbsp;</span>
						</td>
					</tr>
					<tr className="tr-loading">
						<td className="td-1" colSpan={6}>
							<span>&nbsp;</span>
						</td>
					</tr>
				</>
			) : (
				sortData(
					sortField,
					sortDirection,
					search(
						flagLatest(
							data?.filter(
								(x) => x.cashaccount === selectedAccount
							) as TReconciliation[]
						)
					)
				).map(
					({
						computerdate,
						statementdate,
						statementbalance,
						userName,
						cashaccount,
						bookbalance,
						latest,
					}: TReconciliation) => {
						return (
							<tr key={`${cashaccount}-${statementdate}`}>
								<td data-cashaccount={cashaccount}>
									{getAccountName(cashaccount)}
								</td>
								<td>{formatDate(statementdate)}</td>
								<td>{formatMoney(statementbalance)}</td>
								<td>{formatMoney(bookbalance)}</td>
								<td>{formatDate(computerdate)}</td>
								<td>{userName}</td>
								<td>
									<BaseButton
										text={
											<>
												<i className="ri-file-pdf-fill"></i>{' '}
												<span className="tw-underline">PDF</span>
											</>
										}
										buttonClass="text-primary"
										onClick={() =>
											handleOnReportDownload(cashaccount, statementdate)
										}
									/>
								</td>
								<td>
									{latest && (
										<BaseButton
											text={
												<>
													<i className="ri-file-close-fill"></i>{' '}
													<span className="tw-underline">Void</span>
												</>
											}
											buttonClass="text-danger"
											onClick={() => {
												handleOnDelete({
													cashAccount: cashaccount,
													statementDate: statementdate,
												});
											}}
										/>
									)}
								</td>
							</tr>
						);
					}
				)
			)}
		</tbody>
	);
};

CheckbookHistoryTableBody.displayName = 'CheckbookHistoryTableBody';
