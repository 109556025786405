import { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import { useGetPlaidAccounts } from 'api/hooks/useGetPlaidAccounts';
import classNames from 'classnames';
import { displayAlertError } from 'legacy/utilities/Response';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import Table from 'legacy/app/components/newTable/Table';
import { PlaidButton } from './partials/PlaidButton';
import {
	EPlaidAccountStatus,
	IPlaidAccount,
} from 'api/resources/plaidAccounts';
import { TableLinkCell } from './partials/TableLinkCell';
import { Placeholder } from './partials/Placeholder';
import { useGetPayTypes } from 'api/hooks/useGetPayTypes';

const baseColumns: ColumnDef<IPlaidAccount>[] = [
	{
		accessorKey: 'name',
		header: 'Account Name',
		cell: (info) =>
			TableLinkCell({
				info,
			}),
	},
	{
		accessorKey: 'maskedNumber',
		header: 'Account Number',
		cell: (info) => info.getValue(),
	},
	{
		accessorKey: 'type',
		header: 'Type',
		cell: (info) => info.getValue(),
	},
	{
		accessorKey: 'status',
		header: 'Status',
		cell: (info) =>
			info.getValue() === EPlaidAccountStatus.NEEDREAUTHENTICATION
				? PlaidButton({
						accountId: info.row.original.id,
						buttonLabel: 'Reconnect',
				  })
				: info.getValue(),
	},
	{
		header: 'Pay With',
	},
];

export const PlaidConnect = () => {
	const [enableFilters, setEnableFilters] = useState(false);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const {
		data: payTypes,
		isLoading: isFetchingPayTypes,
		error: payTypesError,
	} = useGetPayTypes();
	const { data: accounts, isFetching, error } = useGetPlaidAccounts();

	const columns = useMemo<ColumnDef<IPlaidAccount>[]>(
		() =>
			payTypes
				? [
						...baseColumns.slice(0, -1),
						{
							header: 'Pay With',
							accessorFn: (row) =>
								payTypes.find((payType) =>
									row.payTypeAccountId
										? payType.id === row.payTypeAccountId
										: payType.accountNumber === row.defaultAccountsNumber
								)?.label || '',
						},
				  ]
				: baseColumns,

		[payTypes]
	);

	useEffect(() => {
		if (error || payTypesError) {
			displayAlertError(
				'There was an error getting the accounts information, please try again'
			);
		}
	}, [error, payTypesError]);

	const columnFilterState = {
		columnFilters,
	};

	return (
		<div>
			<div className="tw-flex tw-justify-end tw-items-center tw-p-4 tw-bg-[#EEEDE9] tw-rounded-md tw-gap-4">
				{!isFetching && accounts && accounts.length > 0 && (
					<Button
						className={classNames(
							'!tw-bg-transparent !tw-border-[#dedcd7] !tw-border-[1px] !tw-text-[#211f21] !tw-text-sm !tw-font-bold',
							enableFilters && '!tw-bg-black !tw-text-white'
						)}
						size="sm"
						onClick={() => {
							const value = !enableFilters;
							setEnableFilters(value);
							if (value === false) {
								setColumnFilters([]);
							}
						}}
					>
						<i className="ri-search-line"></i> Search
					</Button>
				)}
				<PlaidButton />
			</div>
			<div className="tw-mt-8">
				{!isFetching && accounts?.length === 0 ? (
					<Placeholder />
				) : (
					<Table
						isLoading={isFetching || isFetchingPayTypes}
						state={columnFilterState}
						onColumnFiltersChange={setColumnFilters}
						data={accounts || []}
						columns={columns}
						enableFilters={enableFilters}
					/>
				)}
			</div>
		</div>
	);
};

PlaidConnect.displayName = 'PlaidConnect';
