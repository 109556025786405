import { apiClient } from 'api/apiClient';

const RESOURCE = '/transactions';

export enum EStatus {
	CLEARED = 'Cleared',
	NEW = 'New',
}

export interface ITransactions {
	id: number;
	instanceId: string;
	accountNumber: string;
	transactionSource: string;
	sourceTransactionId: string;
	amount: number;
	transactionDateUTC: string;
	vendorName: string;
	vendorWebsite: string;
	category: string;
	paymentChannel: string;
	location: string;
	status: `${EStatus}`;
}

export const getTransactions = async (
	filter?: string
): Promise<ITransactions[]> => {
	const { data } = await apiClient.get(
		`${RESOURCE}${filter ? `${filter}` : ''}`.trim()
	);

	return data;
};

export const clearTransactions = async (id: number): Promise<void> => {
	const { data } = await apiClient.post(`${RESOURCE}/${id}/clear`, {});

	return data;
};
