import { apiClient } from 'api/apiClient';
import type { Company } from 'legacy/lib/api/types/Company';

const RESOURCE = '/company';

export const patchCompany = async (params: Company): Promise<Company> => {
	const { data } = await apiClient.patch(`${RESOURCE}/update`, params);
	return data;
};

export const getCompany = async (): Promise<Company> => {
	const { data } = await apiClient.get(RESOURCE);
	return data;
};
