import Header from '../../../../components/Header';
import { Breadcrumb } from 'react-bootstrap';
import URI from '../../../../../defaults/RoutesDefault';
import { Link } from 'react-router-dom';

function ClientPortalSettingsHeader() {
	return (
		<Header>
			<Header.TitleContent>
				<Header.LeftContent>
					<Header.Breadcumbs>
						<Link
							to={URI.settings.list}
							className="text-white active d-flex align-items-center text-decoration-none fw-bold me-4 mb-3"
						>
							<i className="ri-arrow-left-s-line"></i> Back
						</Link>
						<Breadcrumb className="breadcrumb-light">
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: URI.settings.list }}
							>
								Settings
							</Breadcrumb.Item>
							<Breadcrumb.Item active>Client Portal Settings</Breadcrumb.Item>
						</Breadcrumb>
					</Header.Breadcumbs>
					<Header.Title>Client Portal Settings</Header.Title>
				</Header.LeftContent>
			</Header.TitleContent>
		</Header>
	);
}

ClientPortalSettingsHeader.displayName = 'ClientPortalSettingsHeader';
export { ClientPortalSettingsHeader };
