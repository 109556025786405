import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
} from '@tanstack/react-table';
import { useGetTransactions } from 'api/hooks/useGetTransactions';
import { ITransactions } from 'api/resources/transactions';
import Table from 'legacy/app/components/newTable/Table';
import { displayAlertError } from 'legacy/utilities/Response';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TTabOption } from './TransactionTabs';
import { TransactionTableBadge } from './TransactionTableBadge';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { formatDate, formatFilterDate } from 'legacy/helpers/Date';
import { Pagination } from 'legacy/app/components/pagination/Pagination';
import { ExpandablePanel } from './ExpandablePanel';
import { useIsMutating } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';

export const TransactionsTable = ({
	accountId,
	selectedTab,
	enableFilters,
}: {
	enableFilters: boolean;
	accountId: number;
	selectedTab: TTabOption;
}) => {
	const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [pageSize, setPageSize] = useState(20);
	const [page, setPage] = useState(1);
	const isApplyingSuggestion = useIsMutating({
		mutationKey: [ECacheKeys.PostTransactionSuggestions],
	});
	const isClearingTransaction = useIsMutating({
		mutationKey: [ECacheKeys.ClearTransactions],
	});

	const toggleExpanded = useCallback(
		(id: string) => {
			if (expanded[id]) {
				setExpanded({
					...expanded,
					[id]: false,
				});
				return;
			}
			setExpanded({
				...expanded,
				[id]: true,
			});
		},
		[expanded]
	);

	const filter = useMemo(() => {
		let filterString = `?$top=${pageSize + 1}&$skip=${
			(page - 1) * pageSize
		}&$filter=status eq '${
			selectedTab === 'Assigned' ? 'Cleared' : selectedTab
		}' and linkedAccountId eq ${accountId}`;

		if (enableFilters) {
			columnFilters.map((filter) => {
				if (filter.id === 'transactionDateUTC' && filter.value) {
					const startDate = formatFilterDate((filter.value as DateRange)[0]);
					const endDate = formatFilterDate((filter.value as DateRange)[1]);
					filterString = `${filterString} and transactionDateUTC ge ${startDate} and transactionDateUTC le ${endDate}`;
					return;
				} else if (filter.id === 'amount') {
					const isValidNumber = Number(filter.value);
					if (isNaN(isValidNumber)) {
						return;
					}
					filterString = `${filterString} and amount eq ${isValidNumber}`;
					return;
				}
				filterString = `${filterString} and contains(${filter.id}, '${filter.value}')`;
			});
		}

		sorting.map((sort) => {
			filterString = `${filterString} &$orderby=${sort.id} ${
				sort.desc ? 'desc' : 'asc'
			}`;
			return;
		});

		return filterString;
	}, [
		selectedTab,
		columnFilters,
		sorting,
		page,
		pageSize,
		accountId,
		enableFilters,
	]);

	const {
		data: transactions,
		isLoading,
		error,
	} = useGetTransactions(accountId as number, filter);

	const columns = useMemo(() => {
		const columns: ColumnDef<ITransactions>[] = [
			{
				id: 'transactionDateUTC',
				accessorFn: (col) => formatDate(col.transactionDateUTC),
				header: 'Date',
				cell: (info) => info.getValue(),
				meta: {
					CustomFilterInputType: 'date',
				},
			},
			{
				accessorKey: 'vendorName',
				header: 'Name',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'category',
				header: 'Category',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'paymentChannel',
				header: 'Type',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'amount',
				header: 'Amount',
				cell: (info) =>
					(info.getValue() as number).toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
					}),
			},
			{
				header: 'Action',
				enableSorting: false,
				cell: ({ row }) => TransactionTableBadge({ row, toggleExpanded }),
			},
		];
		return selectedTab === 'Assigned'
			? columns.filter((col) => col.header !== 'Action')
			: columns;
	}, [toggleExpanded, selectedTab]);

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error fetching the transactions, please try again'
			);
		}
	}, [error]);

	const slicedTransactions = useMemo(
		() => (transactions ? transactions.slice(0, pageSize) : []),
		[transactions, pageSize]
	);

	return (
		<div className="tw-flex tw-flex-col tw-gap-4">
			<Table
				expanded={expanded}
				manualSorting
				enableExpanding
				onSortingChange={setSorting}
				manualFiltering
				renderExpandableComponent={(row) => (
					<ExpandablePanel
						row={row}
						toggleExpanded={toggleExpanded}
						setPage={setPage}
					/>
				)}
				state={{ columnFilters, sorting }}
				onColumnFiltersChange={setColumnFilters}
				columns={columns}
				isLoading={
					isLoading || !!isApplyingSuggestion || !!isClearingTransaction
				}
				data={slicedTransactions}
				enableFilters={enableFilters}
			/>
			{transactions && transactions.length > 0 && (
				<Pagination
					disableButtons={!!isApplyingSuggestion || !!isClearingTransaction}
					onPageSizeChanged={setPageSize}
					onPageChanged={setPage}
					hasPreviousPage={page > 1 || (isLoading && false)}
					hasNextPage={
						transactions.length > pageSize || (isLoading && false) || false
					}
					page={page}
					pageSize={pageSize}
				/>
			)}
		</div>
	);
};

TransactionsTable.displayName = 'TransactionsTable';
