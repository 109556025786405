import {
	CurrencyType,
	LocaleType,
} from 'legacy/app/enums/utiles/CurrencyTypes';

export function formatReconciliationCurrency(
	amount: number,
	locale = LocaleType.EN_US,
	currency = CurrencyType.USD
) {
	const USDollar = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
	});

	return USDollar.format(amount);
}
