import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ITransactionSuggestions } from 'api/resources/transactionSuggestions';
import { getSuggestions } from 'api/resources/transactionSuggestions';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetTransactionSuggestions = (
	id: number,
	options?: UseQueryOptions<ITransactionSuggestions[]>
) => {
	return useQuery({
		queryKey: [ECacheKeys.TransactionSuggestions, id],
		queryFn: () => getSuggestions(id),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});
};
