import { CheckbookHistoryContext } from '../context/CheckbookHistoryContext';
import { useContext } from 'react';

export const useCheckbookHistory = () => {
	const context = useContext(CheckbookHistoryContext);
	if (context === undefined) {
		throw new Error(
			'useCheckbookHistory must be used within a CheckbookHistoryProvider'
		);
	}
	return context;
};
