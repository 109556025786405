import React from 'react';
import { Breadcrumb, Container, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import ThreadComponent from '../ThreadComponent';
import { getActiveMenuUri, WithRouter } from '../../../helpers/Router';
import URI from '../../../defaults/RoutesDefault';
import Header from '../../components/Header';
import { compareStr } from '../../../helpers/String';
import { getSubComponent } from '../../../helpers/Util';
import AccountSettingsAccounts from './AccountSettingsAccounts';
import AccountSettingsFiscalMonth from './AccountSettingsFiscalMonth';
import AccountSettingsReceivable from './AccountSettingsReceivable';
import AccountSettingsPayment from './AccountSettingsPayment';
import { getLocalStorage } from '../../../utilities/LocalStorage';
import { getSaveButtonName } from '../../../utilities/String';
import { ApiService } from '../../../lib/api/HttpService';
import { displayAlert } from '../../../utilities/Response';
import { Is } from '../../../helpers/Util';
import { PlaidConnect } from 'features/PlaidConnect/PlaidConnect';

class AccountSettings extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['accounts', 'fiscalm', 'receivable', 'payment', 'plaid-accounts'],
				'accounts'
			),
			isLoading: false,
		};

		this.api = new ApiService();
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleClickSave = (e) => {
		const company2 = getLocalStorage('company2_settings_edit', true);
		this.setIsLoading(true);
		this.api.patchCompany2(company2).then((res) => {
			const company = getLocalStorage('company_settings_edit', true);
			if (!Is.empty(company)) {
				this.api.patchCompany(company).then((res) => {
					displayAlert('success', 'Successfully saved changes.');
					this.setIsLoading(false);
				});
			} else {
				displayAlert('success', 'Successfully saved changes.');
				this.setIsLoading(false);
			}
		});
	};

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	renderHeader() {
		const { activeMenu } = this.state;

		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<NavLink
								to={URI.settings.list}
								className="text-white active d-flex align-items-center text-decoration-none fw-bold me-4 mb-3"
							>
								<i className="ri-arrow-left-s-line"></i> Back
							</NavLink>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{ to: URI.settings.list }}
									linkAs={Link}
								>
									Settings
								</Breadcrumb.Item>
								<Breadcrumb.Item>Accounting Settings</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Accounting Settings</Header.Title>
					</Header.LeftContent>
				</Header.TitleContent>
				<Header.Buttons>
					<li>
						<Link
							to={URI.accountSettings.base + '/accounts'}
							data-menu="accounts"
							className={`btn text-white ${compareStr(
								activeMenu,
								'accounts',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Accounts
						</Link>
					</li>
					<li>
						<Link
							to={URI.accountSettings.base + '/fiscalm'}
							data-menu="fiscalm"
							className={`btn text-white ${compareStr(
								activeMenu,
								'fiscalm',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Fiscal Month
						</Link>
					</li>
					<li>
						<Link
							to={URI.accountSettings.base + '/receivable'}
							data-menu="receivable"
							className={`btn text-white ${compareStr(
								activeMenu,
								'receivable',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Accounts Receivable
						</Link>
					</li>
					<li>
						<Link
							to={URI.accountSettings.base + '/payment'}
							data-menu="payment"
							className={`btn text-white ${compareStr(
								activeMenu,
								'payment',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Accounts Payable
						</Link>
					</li>

					<li>
						<Link
							to={URI.accountSettings.base + '/plaid-accounts'}
							data-menu="plaid-accounts"
							className={`btn text-white ${compareStr(
								activeMenu,
								'plaid-accounts',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Bank Connect
						</Link>
					</li>
				</Header.Buttons>
			</Header>
		);
	}

	renderContent() {
		const { activeMenu } = this.state;

		return getSubComponent(activeMenu, {
			accounts: <AccountSettingsAccounts />,
			fiscalm: <AccountSettingsFiscalMonth />,
			receivable: <AccountSettingsReceivable />,
			payment: <AccountSettingsPayment />,
			'plaid-accounts': <PlaidConnect />,
		});
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(AccountSettings);
