import { useCallback, useEffect, useState } from 'react';
import { useGetRunReports } from 'api/hooks/useGetRunReports';
import { useSession } from 'legacy/app/context/session';
import { getCookie } from 'legacy/utilities/Auth';
import {
	IGetRunReportResponse,
	IPostRunReportsParams,
} from 'api/resources/runReports';
import {
	displayAlertError,
	displayAlertLoader,
	hideAlertLoader,
} from 'legacy/utilities/Response';
import { usePostRunReports } from 'api/hooks/usePostRunReports';
import { usePostPublicMappings } from 'api/hooks/usePostPublicMappings';

const RETRY_INTERVAL_MS = 1000;

type TReport = 'reconcile' | 'history';

enum EReportStatus {
	Complete = 'Complete',
	Error = 'Error',
}

const useCheckbookReport = () => {
	const { userId } = useSession();
	const instanceId = getCookie('dmInstanceId');
	const [reportId, setReportId] = useState<string | null>(null);
	const { mutateAsync: postRunReports } = usePostRunReports();
	const { mutateAsync: postPublicMappings } = usePostPublicMappings();

	const { data: reportArray, error } = useGetRunReports(reportId ?? '', {
		enabled: !!reportId,
		refetchIntervalInBackground: false,
		refetchInterval: (data) => {
			const [response] = data || [];
			return response?.status === EReportStatus.Complete ||
				response?.status === EReportStatus.Error ||
				response?.hasTimedOut
				? false
				: RETRY_INTERVAL_MS;
		},
	});

	const displayError = useCallback(
		() => displayAlertError('Could not generate the report. Try again later.'),
		[]
	);

	const handleOnGetReportSuccess = useCallback(
		async (runReportResponse: IGetRunReportResponse[]) => {
			const [firstReportResponse] = runReportResponse;
			const { status, completedFileId: fileId } = firstReportResponse;
			if (status === EReportStatus.Complete && fileId) {
				try {
					const { link } = await postPublicMappings({
						fileId,
						label: 'Checkbook Reconciliation',
					});
					if (link) {
						window.open(link, '_blank');
					}
				} catch {
					displayError();
				} finally {
					hideAlertLoader();
				}
			}
		},
		[postPublicMappings, displayError]
	);

	useEffect(() => {
		const handleReport = async (data: IGetRunReportResponse[]) => {
			const [response] = data || [];
			if (response?.hasTimedOut || response?.status === EReportStatus.Error) {
				displayError();
				return;
			}

			await handleOnGetReportSuccess(data);
		};
		if (reportArray) {
			handleReport(reportArray);
		}
	}, [reportArray, handleOnGetReportSuccess, displayError]);

	useEffect(() => {
		if (error) {
			displayError();
		}
	}, [error, displayError]);

	const downloadCheckbookReport = async (report: TReport, value: string) => {
		try {
			const reportConfig: {
				[key in TReport]: { reportId: number; reportFormatId: number };
			} = {
				reconcile: {
					reportId: 1174,
					reportFormatId: 1350,
				},
				history: {
					reportId: 1175,
					reportFormatId: 1351,
				},
			};

			const payload: IPostRunReportsParams = {
				...reportConfig[report],
				reportObjectType: 'Report',
				instanceId,
				userId,
				objectId: null,
				runReportParameters: [
					{
						parameterId: 734,
						value,
					},
				],
			};

			displayAlertLoader('Please wait for your report to be generated.');
			const { id } = await postRunReports(payload);
			if (id) {
				setReportId(id);
			}
		} catch {
			displayAlertError('Could not generate the report. Try again later.');
		}
	};

	return { downloadCheckbookReport };
};

export { useCheckbookReport };
