import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useController, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import AccountsDropdown, {
	EAccountType,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { Controller } from 'react-hook-form';
import CurrencyInput from 'legacy/pages/accounts-payable/credit-card/reconcile/CurrencyInput';
import cn from 'classnames';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import { TCashAccount, TFormValues } from './types/CheckbookReconcileTypes';
import { useAccounts } from 'legacy/pages/accounts-payable/credit-card/reconcile/hooks';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

const CheckbookReconcileForm = () => {
	const {
		started,
		setFormSubmitted,
		setCheckbook,
		resetState,
		postTotalsReconciliation,
		hasOutstandingTaggedItems,
		openPrintModal,
	} = useCheckbookReconcile();
	const { data: accounts } = useAccounts(EAccountType.CHECKING);
	const [newAccount, setNewAccount] = useState<TCashAccount | null>(null);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const {
		control,
		formState: { errors, isValid },
		getValues,
		handleSubmit,
		setValue,
	} = useForm<TFormValues>({
		defaultValues: {
			cashAccount: null,
			statementDate: null,
			endingBalance: null,
		},
	});

	const { field } = useController({
		name: 'cashAccount',
		control,
		rules: { required: true },
	});

	// Set the first account.
	useEffect(() => {
		if (!accounts) return;
		const [firstAccount] = accounts;
		if (firstAccount) {
			setValue('cashAccount', {
				label: `[${firstAccount.account}] ${firstAccount.accountn}`,
				value: firstAccount.account,
			});
		}
	}, [accounts, setValue]);

	const onConfirm = () => {
		setValue('cashAccount', newAccount);
		setValue('endingBalance', null, { shouldValidate: true });
		setValue('statementDate', null, { shouldValidate: true });
		resetState();
		setShowConfirmationModal(false);
	};

	const onCancel = () => {
		setNewAccount(null);
		setShowConfirmationModal(false);
	};

	const handleOnAccountChange = (account: TCashAccount) => {
		if (started && hasOutstandingTaggedItems()) {
			setNewAccount(account);
			setShowConfirmationModal(true);
		} else {
			setValue('cashAccount', account);
			setValue('endingBalance', null, { shouldValidate: true });
			setValue('statementDate', null, { shouldValidate: true });
			resetState();
		}
	};

	const onSubmit = () => {
		const { cashAccount, statementDate, endingBalance } = getValues();
		if (!cashAccount || !statementDate || typeof endingBalance !== 'number') {
			return;
		}

		setCheckbook({
			cashAccount: cashAccount.value,
			statementDate: dayjs(new Date(statementDate)).format('YYYY-MM-DD'),
			endingBalance: endingBalance,
		});

		setFormSubmitted(true);
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="tw-grid tw-grid-flow-col tw-justify-start tw-align-baseline tw-gap-2 lg:tw-gap-4"
			>
				<div className="tw-grid tw-grid-flow-row tw-justify-center tw-items-center tw-gap-1">
					<label>Account *</label>
					<AccountsDropdown
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...field}
						className={cn('tw-self-end tw-w-64 tw-h-10', {
							'tw-border tw-rounded tw-border-red-500': errors.cashAccount,
						})}
						type={EAccountType.CHECKING}
						onChange={handleOnAccountChange}
					/>
				</div>

				<div className="tw-grid tw-grid-flow-row tw-justify-center tw-items-center tw-gap-1">
					<label>Statement Ending Date *</label>
					<div>
						<Controller
							control={control}
							name="statementDate"
							rules={{ required: true }}
							render={({ field: { onChange, value } }) => (
								<ReactDatePicker
									dateFormat="MM/dd/yyyy"
									onChange={onChange}
									className={cn(
										'form-control form-control-sm tw-w-48 tw-h-10',
										{
											'border-danger': errors.statementDate,
										}
									)}
									showMonthDropdown={true}
									showYearDropdown={true}
									placeholderText="mm-dd-yyyy"
									autoComplete="off"
									selected={value}
									disabled={started}
								/>
							)}
						/>
					</div>
				</div>

				<div className="tw-grid tw-grid-flow-row tw-justify-center tw-items-center tw-gap-1">
					<label>Statement Ending Balance *</label>
					<CurrencyInput
						className={cn('tw-w-48 tw-h-10', {
							'border-danger': errors.endingBalance,
						})}
						defaultValue={''}
						name="endingBalance"
						control={control}
						rules={{ required: true }}
						disabled={started}
					/>
				</div>
				<div className="tw-self-end">
					{started ? (
						<div className="tw-grid tw-grid-flow-col tw-gap-2">
							<Button
								variant="primary"
								type="button"
								onClick={postTotalsReconciliation}
							>
								Accept Reconciliation
							</Button>
							<Button
								variant="outline-dark"
								type="button"
								onClick={openPrintModal}
							>
								Get PDF
							</Button>
						</div>
					) : (
						<Button variant="primary" type="submit" disabled={!isValid}>
							Start Reconcile
						</Button>
					)}
				</div>
			</form>
			{!started && (
				<div className="tw-mb-2">
					Select an{' '}
					<span className="tw-border tw-border-[#dedcd7] tw-rounded tw-px-0.5">
						Account
					</span>
					,{' '}
					<span className="tw-border tw-border-[#dedcd7] tw-rounded tw-px-0.5">
						Statement Ending Date
					</span>{' '}
					and{' '}
					<span className="tw-border tw-border-[#dedcd7] tw-rounded tw-px-0.5">
						Statement Ending Balance
					</span>{' '}
					to start the reconciliation.
				</div>
			)}

			<ConfirmationModal
				confirmAction={onConfirm}
				show={showConfirmationModal}
				toggleModal={onCancel}
				title="Exit Reconciliation"
				okBtnStyle="primary"
				labelOK="Yes"
				labelCancel="No"
			>
				<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-center tw-gap-2 tw-px-2">
					<i className="ri-question-line tw-text-xl tw-text-blue-700"></i>
					<span className="tw-text-sm">
						Are you sure you want to leave this reconciliation? <br />
						All tagged Outstanding entries that you have not marked as cleared
						will no longer be tagged for this account upon your return.
					</span>
				</div>
			</ConfirmationModal>
		</>
	);
};

CheckbookReconcileForm.displayName = 'CheckbookReconcileForm';

export { CheckbookReconcileForm };
