import { useGetFinanceCharges } from 'api/hooks/useGetFinanceCharges';
import { usePostVoidFinanceCharges } from 'api/hooks/usePostVoidFinanceCharge';
import { queryClient } from 'api/queryClient';
import { IJournalEntry } from 'api/resources/financeCharges';
import { ECacheKeys } from 'cache/CacheKeys';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
	TEditFinanceCharge,
	TFinanceChargeAccount,
	TOnUpdateArgs,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeTypes';
import { FinanceChargeCreate } from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/partials/FinanceChargeCreate';
import { FinanceChargeDelete } from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/partials/FinanceChargeDelete';
import { FinanceChargeOverview } from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/partials/FinanceChargeOverview';
import { FinanceChargesVoid } from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/partials/FinanceChargesVoid';
import { FinanceChargeUpdate } from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/partials/FinanceChargeUpdate';

import { useEffect, useState } from 'react';

type TFinanceChargeProps = TEditFinanceCharge & {
	show: boolean;
	onToggleModal: () => void;
	account: TFinanceChargeAccount;
	statementDate: string;
	invalidateReconciliationQueries: () => void;
};

dayjs.extend(customParseFormat);

export const FinanceCharge = ({
	show,
	onToggleModal,
	account,
	fiscalMonth,
	isRevision,
	statementDate,
	invalidateReconciliationQueries,
}: TFinanceChargeProps) => {
	const [memoryJournalEntries, setMemoryJournalEntries] = useState<
		IJournalEntry[] | null
	>(null);
	const [showCreateChargeModal, setShowCreateChargeModal] = useState(false);
	const [showUpdateChargeModal, setShowUpdateChargeModal] = useState(false);
	const [showDeleteEntryModal, setShowDeleteEntryModal] = useState(false);
	const [selectedAccountAmount, setSelectedAccountAmount] = useState<
		number | null
	>(null);
	const [showVoidEntireChargeModal, setShowVoidEntireChargeModal] =
		useState(false);
	const [selectedAccountId, setSelectedAccountId] =
		useState<TFinanceChargeAccount | null>(null);
	const [isDirty, setIsDirty] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);

	// financeCharges = temporary journal entries in BE
	// memoryJournalEntries = journal entries in browser memory (only in FE)
	const financeCharges = useGetFinanceCharges({
		isCheckbook: false,
		statementEndingDate: dayjs(statementDate).format('YYYY-MM-DD'),
		account: account.value,
	});

	const { mutate } = usePostVoidFinanceCharges();

	const onCreateEntry = ({
		account,
		damt,
	}: {
		account: TFinanceChargeAccount | null;
		damt: number;
	}) => {
		setMemoryJournalEntries([
			...(memoryJournalEntries || []),
			{
				account: account?.value as string,
				accountName: account?.label as string,
				damt,
				camt: 0,
			},
		]);

		setIsDirty(true);
	};

	const onUpdateEntry = (
		values: TOnUpdateArgs,
		hasUserChangedAccount: boolean
	) => {
		const newJournalEntries = memoryJournalEntries
			// If user changed account; we will temporarily remove the entry
			// from the array and add it back at the end as a new account
			// since technically speaking they are different entries now
			?.filter((entry) => {
				return hasUserChangedAccount
					? entry.account !== values?.account?.value
					: true;
			})
			.map((entry) => {
				if (entry.account === values?.account?.value) {
					return {
						...entry,
						account: values?.newAccount?.value as string,
						accountName: values?.newAccount?.label,
						damt: values.damt,
					};
				}

				return entry;
			});

		if (hasUserChangedAccount) {
			newJournalEntries?.push({
				account: values?.newAccount?.value as string,
				accountName: values?.newAccount?.label,
				damt: values.damt,
				camt: 0,
			});
		}

		setMemoryJournalEntries(newJournalEntries || []);
		setIsDirty(true);
	};

	const onDeleteEntry = (account: TFinanceChargeAccount) => {
		if (memoryJournalEntries) {
			setMemoryJournalEntries(
				memoryJournalEntries.filter((entry) => entry.account !== account.value)
			);
		}
		setIsDirty(true);
	};

	const onVoidEntireCharge = () => {
		mutate({ transactionNumber: financeCharges?.data?.txNum.toString() || '' });
		setShowVoidEntireChargeModal(false);
		setMemoryJournalEntries(null);
		onToggleModal();
		setIsDirty(true);
	};

	const reset = async () => {
		invalidateReconciliationQueries();
		setIsDirty(true);

		await queryClient.resetQueries([ECacheKeys.FinanceCharges], {
			exact: true,
		});

		await financeCharges.refetch();

		onToggleModal();
	};

	useEffect(() => {
		const temporaryJournalEntryDetailsList =
			financeCharges.data?.temporaryJournalEntryDetailsList.filter((entry) =>
				entry.damt ? entry?.damt > 0 : false
			) as IJournalEntry[];

		if (
			!isDirty &&
			memoryJournalEntries === null &&
			financeCharges.data?.txNum !== -999
		) {
			setMemoryJournalEntries(temporaryJournalEntryDetailsList);
			setInitialLoad(false);
		}
	}, [isDirty, memoryJournalEntries, financeCharges, initialLoad]);

	return (
		<>
			<FinanceChargeOverview
				savedDescription={financeCharges.data?.description}
				journalEntries={memoryJournalEntries || []}
				statementDate={statementDate}
				account={account}
				fiscalMonth={fiscalMonth}
				isRevision={isRevision}
				showModal={show}
				onCreateEntry={() => {
					setShowCreateChargeModal(true);
					setSelectedAccountId(null);
					setSelectedAccountAmount(null);
				}}
				onUpdateEntry={(account, amount) => {
					setShowUpdateChargeModal(true);
					setSelectedAccountId(account);
					setSelectedAccountAmount(amount);
				}}
				onDeleteEntry={(accountId) => {
					setShowDeleteEntryModal(true);
					setSelectedAccountId(accountId);
				}}
				onVoidEntireCharge={() => {
					setShowVoidEntireChargeModal(true);
				}}
				onSubmit={reset}
				onCancel={reset}
				canVoid={financeCharges.data?.txNum > 0}
				isDirty={isDirty}
			/>

			<FinanceChargeCreate
				showModal={showCreateChargeModal}
				onToggleModal={() => {
					setShowCreateChargeModal(false);
					setSelectedAccountId(null);
					setSelectedAccountAmount(null);
				}}
				onCreate={onCreateEntry}
				account={account}
				charge={{
					account: selectedAccountId as TFinanceChargeAccount,
					damt: selectedAccountAmount as number,
				}}
				journalEntries={memoryJournalEntries || []}
			/>

			<FinanceChargeUpdate
				showModal={showUpdateChargeModal}
				onToggleModal={() => {
					setShowUpdateChargeModal(false);
					setSelectedAccountId(null);
					setSelectedAccountAmount(null);
				}}
				onUpdate={onUpdateEntry}
				account={account}
				charge={{
					account: selectedAccountId as TFinanceChargeAccount,
					damt: selectedAccountAmount as number,
				}}
				journalEntries={memoryJournalEntries || []}
			/>

			<FinanceChargeDelete
				showModal={showDeleteEntryModal}
				onToggleModal={() => setShowDeleteEntryModal(false)}
				selectedEntryAccount={selectedAccountId}
				onDelete={onDeleteEntry}
			/>

			<FinanceChargesVoid
				showModal={showVoidEntireChargeModal}
				onToggleModal={() => setShowVoidEntireChargeModal(false)}
				confirmAction={() => onVoidEntireCharge()}
			/>
		</>
	);
};

FinanceCharge.displayName = 'FinanceCharge';
