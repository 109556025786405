import {
	UseQueryOptions,
	UseQueryResult,
	useQuery,
} from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';
import { Component } from '../types/Component';

const api = new ApiService();

const getComponent = async (id: string): Promise<Component[]> => {
	const components = await api.getComponentsWithoutPermissions(
		`?$filter=id eq ${id}`
	);

	return components;
};

const useGetComponent = (
	id: string,
	options?: UseQueryOptions<Component[], unknown, Component[], string[]>
): UseQueryResult<Component[], unknown> => {
	const componentsQuery = useQuery({
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryKey: [id, 'components'],
		queryFn: () => getComponent(id),
		enabled: !!id,
		...{ ...(options || {}) },
	});
	useEffect(() => {
		if (componentsQuery.error) {
			displayAlertError(
				'An error occurred fetching the component, please try again'
			);
		}
	}, [componentsQuery.error]);
	return componentsQuery;
};
export default useGetComponent;
