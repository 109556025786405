import { currencyFormat } from 'legacy/helpers/Number';
import { truncateCharCount } from 'legacy/helpers/String';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import useGetComponents, {
	TComponentWithTxNum,
} from 'legacy/lib/api/hooks/useGetComponents';
import useGetCompany from 'legacy/lib/api/hooks/useGetCompanyInfo';
import React from 'react';
import getCompanyTypeMap from 'legacy/utilities/getComponentTypeMap';
import useDeleteObject from 'legacy/lib/api/hooks/useDeleteObject';
import { DeleteType } from 'legacy/lib/api/types/DeleteType';
import { DeleteObjectTypes } from 'legacy/lib/api/types/DeleteObjectTypes';
import PurchaseComponentsButton from './PurchaseComponentsButton';
import ComponentsDeleteWarningModal from './ComponentsDeleteWarningModal';
import { displayAlertSuccess } from 'legacy/utilities/Response';
import { Component } from 'legacy/lib/api/types/Component';
import ComponentsDeleteConfirmationModal from 'legacy/app/components/ComponentDeleteConfirmationModal/ComponentsDeleteConfirmationModal';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'legacy/app/components/newTable/Table';
import TableCheckbox from './TableCheckboxCell';
import TableButtonCell from './TableButtonCell';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { Item } from 'legacy/lib/api/types/Item';
import {
	useHasAccess,
	SECURITY_ATTRIBUTE_TYPES,
} from 'legacy/app/context/security';

export type TFailedComponentRequest = {
	comp: string;
	message: string;
};

const PurchaseComponentsTable = ({
	submitMethods,
	disableButtons,
	disableAdd,
	isItemFromInventory,
	setDelayReload,
	hasSalesCategory,
}: {
	isItemFromInventory: boolean;
	disableAdd: boolean;
	disableButtons: boolean;
	submitMethods: (
		customRedirectPath?: string,
		getEditedItem?: (item: Item) => void
	) => Promise<void>;
	setDelayReload: (value: boolean) => void;
	hasSalesCategory: boolean;
}) => {
	const [checkedItems, setCheckedItems] = useState<{
		[key: string]: boolean;
	}>({});

	const [failedComponentsDelete, setFailedComponentsDelete] = useState<
		TFailedComponentRequest[]
	>([]);

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const [showFailedComponentsnModal, setShowFailedComponentsModal] =
		useState(false);

	const [disableAllInputsAndButtons, setDisableAllInputsAndButtons] =
		useState(false);

	const { id: projectId, itemId } = useParams();

	const { data: project } = useGetProject(projectId as string);

	const { mutateAsync, isLoading: isDeleting } = useDeleteObject();

	const canSeeEstCost = useHasAccess(14, SECURITY_ATTRIBUTE_TYPES.DenySpec8);

	const {
		data: components,
		isFetching: isFetchingComponents,
		refetch: fetchComponents,
	} = useGetComponents(itemId as string, projectId as string);

	const disableInputs =
		disableAllInputsAndButtons ||
		isFetchingComponents ||
		isDeleting ||
		disableButtons;

	const { data: company } = useGetCompany({
		enabled: !!itemId && !!projectId,
	});

	const actualCheckedItems = Object.keys(checkedItems).filter(
		(key) => checkedItems[Number(key)]
	);

	const handleComponentDelete = async () => {
		setDisableAllInputsAndButtons(true);

		const notDeletedComponents: TFailedComponentRequest[] = [];
		if (!components) {
			return;
		}
		for (const comp of components) {
			const component = actualCheckedItems.find(() =>
				actualCheckedItems.includes(String(comp.id))
			);

			if (component) {
				try {
					const canBeDeleted = await mutateAsync({
						deleteType: DeleteType.dmriTestOnly,
						objectType: DeleteObjectTypes.objComponent,
						objectCodeOrId: comp.id,
					});

					if (canBeDeleted.fullDeletePossible) {
						await mutateAsync({
							deleteType: DeleteType.dmriTestAndFullDeleteIfPossible,
							objectType: DeleteObjectTypes.objComponent,
							objectCodeOrId: comp.id,
						});
						setCheckedItems({
							[comp.id]: false,
						});
					} else if (!canBeDeleted.fullDeletePossible) {
						notDeletedComponents.push({
							comp: comp.comp,
							message: canBeDeleted.message,
						});
					}
				} catch {
					notDeletedComponents.push({
						comp: comp.comp,
						message: 'Network error, please try again',
					});
				}
			}
		}

		setFailedComponentsDelete(notDeletedComponents);

		if (notDeletedComponents.length) {
			setShowFailedComponentsModal(true);
		}

		if (!notDeletedComponents.length) {
			displayAlertSuccess('Components deleted successfully!');
		}

		const newComponents = await fetchComponents();

		const newCheckedItems: {
			[key: string]: boolean;
		} = {};

		newComponents.data?.map((comp) => {
			if (checkedItems[String(comp.id)]) {
				newCheckedItems[String(comp.id)] = true;
			}
		});

		setCheckedItems(newCheckedItems);
		setDisableAllInputsAndButtons(false);
	};

	const columns = useMemo<ColumnDef<TComponentWithTxNum>[]>(
		() => [
			{
				id: 'select',
				size: 50,
				header: ({ table }) =>
					TableCheckbox({
						table,
						disabled: disableInputs || !!project?.closeddt,
					}),
				cell: ({ row }) =>
					TableCheckbox({
						table: undefined,
						disabled: disableInputs || !!project?.closeddt,
						row,
					}),
			},
			{
				accessorKey: 'comp',
				header: 'Comp.#',
				size: 50,
				cell: (info) =>
					TableButtonCell({
						info,
						isItemFromInventory,
						submitMethods,
						disabled: disableInputs,
						doNotSubmit: !!project?.closeddt,
					}),
			},
			{
				accessorKey: 'desc',
				header: 'Description',
				size: 300,
				enableSorting: false,
				cell: (info) => truncateCharCount(info.getValue(), 100),
			},
			{
				header: 'Quantity',
				enableSorting: false,
				cell: (info) =>
					`${info.row.original.quantity} ${
						info.row.original.unitmeasure || ''
					}`,
			},
			{
				header: 'Vendor',
				enableSorting: false,
				cell: (info) => info.row.original.vendorName,
			},
			{
				accessorFn: (col) =>
					company ? getCompanyTypeMap(company)[col.comptype] : null,
				header: 'Type',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.estcost, '$'),
				header: 'Est. Cost',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.estprice, '$'),
				header: 'Est. Price',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
		],
		[company, disableInputs, submitMethods, project, isItemFromInventory]
	);

	const filteredColumns = useMemo(
		() =>
			canSeeEstCost
				? columns
				: columns.filter((col) => col.header !== 'Est. Cost'),
		[columns, canSeeEstCost]
	);

	return (
		<div className="tw-max-w-full tw-p-4">
			<div className="tw-flex tw-justify-between tw-items-center tw-p-7 tw-bg-[#EEEDE9]">
				<p className="tw-text-base tw-font-bold tw-m-0">
					Purchase Components Information
				</p>
				<div className="tw-flex tw-gap-2">
					<PurchaseComponentsButton
						removeStockButton={!!project?.closeddt}
						components={components as Component[]}
						disableDelete={disableInputs || actualCheckedItems.length === 0}
						disableAdd={disableInputs || disableAdd}
						submitMethods={submitMethods}
						onDeleteClick={() => setShowConfirmationModal(true)}
						setDelayReload={setDelayReload}
						firstComponent={components?.[0]}
						hasSalesCategory={hasSalesCategory}
					/>
				</div>
			</div>
			<ComponentsDeleteWarningModal
				failedComponentsDelete={failedComponentsDelete}
				show={showFailedComponentsnModal}
				confirmAction={() => {
					setShowFailedComponentsModal(false);
					setTimeout(() => setFailedComponentsDelete([]), 200);
				}}
			/>
			<ComponentsDeleteConfirmationModal
				show={showConfirmationModal}
				toggleModal={() => setShowConfirmationModal(false)}
				confirmAction={() => {
					setShowConfirmationModal(false);
					handleComponentDelete();
				}}
			/>
			{components && components.length > 0 && (
				<Table
					data={components}
					columns={filteredColumns}
					state={{
						rowSelection: checkedItems,
					}}
					getRowId={(comp) => String(comp.id)}
					onRowSelectionChange={setCheckedItems}
				/>
			)}
		</div>
	);
};
PurchaseComponentsTable.displayName = 'PurchaseComponentsTable';
export default PurchaseComponentsTable;
