import React from 'react';
import ThreadComponent from '../../ThreadComponent';
import { observer } from 'mobx-react';
import VendorAddEditHeader from './components/VendorAddEditHeader';
import { WithRouter, pathParam } from '../../../utilities/Router';
import { Container } from 'react-bootstrap';
import VendorAddEditInfo from './components/VendorAddEditInfo';
import VendorAddEditDefaults from './components/VendorAddEditDefaults';
import VendorAddEditViewModel from './VendorAddEditViewModel';
import AddressAddEditViewModel from '../Address/AddressAddEditViewModel';
import PayeeAddressAddEditViewModel from '../Address/PayeeAddressAddEditViewModel';
import VendorAddEditNotes from './components/VendorAddEditNotes';
import VendorEditStatus from './components/VendorEditStatus';
import URI from '../../../defaults/RoutesDefault';
import { getLocalStorage } from '../../../utilities/LocalStorage';
import { validateInput } from '../../components/validators/Helpers';
import { VendorValidation } from './validations/VendorValidation';
import { displayAlert, handleResponseAlert } from '../../../utilities/Response';
import { cloneDeep, isEmpty, isNull, isUndefined } from 'lodash';
import { ErrorObjects } from '../../../defaults/Error';
import SecureContent from '../../components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from '../../context/security';
import Documents from '../../../templates/modules/documents/Documents';

interface Props {
	vendorAddEditModel: VendorAddEditViewModel;
	addressAddEditModel: AddressAddEditViewModel;
	payeeAddressAddEditModel: PayeeAddressAddEditViewModel;
	params?: any;
	navigate?: any;
}

interface SortTypes {
	value: string;
	label: string;
}

interface State {
	data: any;
	sortTypes: SortTypes[];
	isLoading: boolean;
	isDraft: boolean;
	numAttachments?: number;
}

class VendorAddEditComponent extends ThreadComponent<Props, State> {
	readonly vendorAddEditModel: VendorAddEditViewModel;
	readonly addressAddEditModel: AddressAddEditViewModel;
	readonly payeeAddressAddEditModel: PayeeAddressAddEditViewModel;
	readonly isEditing: boolean;

	constructor(props: Props) {
		super(props);

		this.vendorAddEditModel = props.vendorAddEditModel;
		this.addressAddEditModel = props.addressAddEditModel;
		this.payeeAddressAddEditModel = props.payeeAddressAddEditModel;
		this.state = {
			data: {},
			sortTypes: [
				{ value: 'Name', label: 'Name' },
				{ value: 'Company', label: 'Company' },
				{ value: 'Manual', label: 'Manual' },
			],
			isLoading: false,
			isDraft: false,
			numAttachments: undefined,
		};

		this.setIsLoaded(true);
		this.isEditing = Boolean(this.props.params.id);
	}

	async componentDidMount() {
		this.setTitle('Add Vendor').setBodyClass();

		this.vendorAddEditModel.reset();
		this.resetLocalStorage();
		this.dMloader(true);

		try {
			if (this.props.params.id !== undefined) {
				this.vendorAddEditModel.setId(this.props.params.id);

				await this.vendorAddEditModel.componentDidMount();

				if (isEmpty(this.vendorAddEditModel.vendor)) {
					throw ErrorObjects.Notfound;
				}

				this.addressAddEditModel.setClientCode(
					this.vendorAddEditModel.vendor.vendor
				);
				this.addressAddEditModel.setAddressType(2);
				await this.addressAddEditModel.componentDidMount();

				this.payeeAddressAddEditModel.setClientCode(
					this.vendorAddEditModel.vendor.vendor
				);
				this.payeeAddressAddEditModel.setAddressType(3);
				await this.payeeAddressAddEditModel.componentDidMount();
				this.retrieveDraft();
			} else {
				//reset forms
				this.addressAddEditModel.setClientCode('');
				this.addressAddEditModel.setAddressType(0);
				this.addressAddEditModel.setAddress({});
				this.payeeAddressAddEditModel.setClientCode('');
				this.payeeAddressAddEditModel.setAddressType(0);
				this.vendorAddEditModel.reset();
			}
		} catch (error) {
			this.setIsFound(false).setError(error, true);
		}

		this.dMloader(false);
		window.localStorage.setItem('isDraft', 'false');
	}

	componentWillUnmount(): void {
		this.resetLocalStorage();
	}

	retrieveDraft() {
		const isDraft = getLocalStorage('isDraft');
		const vendorData = isDraft
			? getLocalStorage('vendorData', true)
			: this.vendorAddEditModel.vendorData;
		const addressData = isDraft
			? getLocalStorage('addressData', true)
			: this.addressAddEditModel.addressData;
		const payeeAddressData = isDraft
			? getLocalStorage('payeeAddressData', true)
			: this.payeeAddressAddEditModel.addressData;
		this.vendorAddEditModel.setVendorData('addressPayee', payeeAddressData);
		this.vendorAddEditModel.setVendorData('address', addressData);

		const payeesameasvendor = vendorData.payeesameasvendor ?? true;
		this.vendorAddEditModel.setVendorData(
			'payeesameasvendor',
			payeesameasvendor
		);

		if (!isDraft) {
			window.localStorage.setItem('addressData', JSON.stringify(addressData));
			window.localStorage.setItem(
				'payeeAddressData',
				JSON.stringify(payeeAddressData)
			);
		}

		this.setState({
			data: this.vendorAddEditModel.vendorData,
		});
	}

	setIsLoading(isLoading: boolean): void {
		this.setState({
			isLoading: isLoading,
		});
	}

	resetLocalStorage(): void {
		window.localStorage.removeItem('vendorData');
		window.localStorage.removeItem('addressData');
		window.localStorage.removeItem('payeeAddressData');
		window.localStorage.setItem('isDraft', 'false');
		window.localStorage.removeItem('authToken');
	}

	redirectPage(clientData: any, res: any, element: any) {
		if (!this.hasError(res)) {
			this.resetLocalStorage();
			if (element.target.name === 'saveNew') {
				window.location.href = pathParam(URI.contact.vendorAdd, {
					tab: 'info',
				});
			} else {
				this.props.navigate(URI.contact.vendor);
			}
		}
	}

	errorMessage(response: any) {
		const errorTextDiv: any = document.getElementById('userError');
		this.toggleErrorDiv(false);

		if (response.response && response.response.status !== 200) {
			this.toggleErrorDiv(true);
			errorTextDiv.innerHTML =
				response.response.data.userError || response.message;
		}
	}

	toggleErrorDiv(isShown: boolean) {
		const errorDiv: any = document.getElementById('user-error-area');
		if (errorDiv) {
			errorDiv.style.display = isShown ? 'block' : 'none';
		}
	}

	hasError(response: any) {
		response = response || {};
		this.errorMessage(response);

		if (response.response && response.response.status !== 200) {
			this.setIsLoading(false);
			return true;
		}

		return false;
	}

	switchTab = (e: any) => {
		this.retrieveDraft();
	};

	onDraft: any = (e: any) => {
		this.setIsDraft();
		this.retrieveDraft();
	};

	setIsDraft = () => {
		window.localStorage.setItem('isDraft', 'true');
		this.setState({
			isDraft: true,
		});
	};

	handleClickSave = async (e: any) => {
		const vendorData: any = getLocalStorage('vendorData', true);
		const addressData: any = getLocalStorage('addressData', true);
		let payeeAddressData: any = getLocalStorage('payeeAddressData', true);

		const validated = await validateInput(VendorValidation, vendorData.address);

		if (!validated.status) {
			delete vendorData.addressPayee;

			if (vendorData.payeesameasvendor) {
				vendorData.payee = addressData.sortname;
			}

			const vendorData2 = cloneDeep(vendorData);
			delete vendorData2.insurances;
			delete vendorData2.address;
			delete vendorData2.addressPayee;
			delete vendorData2.payeeAddressData;

			if (!this.isEditing && isUndefined(vendorData2.incometype)) {
				vendorData2.incometype = '-1';
			}

			// If 1099 = INT - Interest Income, let's require the _1099rp
			if (!vendorData2._1099rp && vendorData2.incometype == 100) {
				vendorData2._1099rp = null;
				vendorData2.acct = null;
			}

			const request = this.vendorAddEditModel.modifyVendor(
				this.vendorAddEditModel.vendor.id,
				vendorData2
			);
			this.setIsLoading(true);
			handleResponseAlert(request, (res: any) => {
				addressData.code = vendorData.vendor;
				addressData.addresstype = 2;

				this.setIsLoading(true);
				this.addressAddEditModel.modifyAddress(
					addressData.id,
					addressData,
					() => {
						const payeeAddressId = payeeAddressData.id;
						payeeAddressData = vendorData.payeesameasvendor
							? addressData
							: payeeAddressData;
						payeeAddressData.addresstype = 3;
						payeeAddressData.code = vendorData.vendor;
						payeeAddressData.id = payeeAddressId;

						this.payeeAddressAddEditModel.modifyAddress(
							payeeAddressData.id,
							payeeAddressData,
							() => {
								if (!res.code) {
									this.redirectPage(vendorData, res, e);
									this.setIsLoading(false);
								} else {
									this.setIsLoading(false);
									const client = document.getElementById('vendor') as any;
									client.disabled = false;
									client.readOnly = false;
								}
							}
						);
					}
				);
			});
		} else {
			displayAlert('danger', validated.message);
		}
	};

	getActiveTabName() {
		return this.props.params && this.props.params.tab
			? this.props.params.tab
			: 'info';
	}

	displayContent(): JSX.Element {
		const tab = this.getActiveTabName();

		const { data } = this.state;

		if (tab === 'info')
			return (
				<VendorAddEditInfo
					onDraft={this.onDraft}
					data={data}
					navigate={this.props.navigate}
				/>
			);

		if (tab === 'status')
			return (
				<SecureContent
					attributeNo={49}
					attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
				>
					<SecureContent.HasAccess>
						<VendorEditStatus vendorId={this.vendorAddEditModel.vendor?.id} />
					</SecureContent.HasAccess>
					<SecureContent.NoAccess>
						<SecureContent.GenericNoAccess />
					</SecureContent.NoAccess>
				</SecureContent>
			);

		if (tab === 'defaults')
			return (
				<VendorAddEditDefaults
					onDraft={this.onDraft}
					data={data}
					setIsDraft={this.setIsDraft}
					isNewVendor={!this.isEditing}
				/>
			);

		if (tab === 'notes')
			return <VendorAddEditNotes onDraft={this.onDraft} data={data} />;

		if (tab === 'documents')
			return (
				<Documents
					objectType="Vendor"
					objectId={this.props.params.id}
					onRefresh={(numAttachments: number) => {
						this.setState({ numAttachments: numAttachments });
					}}
				/>
			);

		return <></>;
	}

	render(): JSX.Element {
		const page = this.getActiveTabName();

		return this.renderView(
			<>
				<VendorAddEditHeader
					activeMenu={page}
					vendorName={this.vendorAddEditModel.vendor?.vendorn}
					vendorId={this.vendorAddEditModel.vendor?.id}
					onSave={this.handleClickSave}
					isLoading={this.state.isLoading}
					onSwitchTab={this.switchTab}
					isDraft={this.state.isDraft}
					numAttachments={
						this.state.numAttachments ??
						this.vendorAddEditModel.vendor?.numAttachments ??
						0
					}
					isSaveHidden={this.getActiveTabName() === 'documents'}
				/>

				<div className="content-padding min-height has-action-bar">
					<Container fluid>{this.displayContent()}</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(observer(VendorAddEditComponent));
