import { useGetPlaidAccounts } from 'api/hooks/useGetPlaidAccounts';
import { IndividualAccountHeader } from './IndividualAccountHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'legacy/app/components/help/Spinner';
import { useEffect, useMemo, useState } from 'react';
import {
	displayAlertError,
	displayAlertSuccess,
} from 'legacy/utilities/Response';
import Label from 'legacy/app/components/label/Label';
import { Button, Form } from 'react-bootstrap';
import { EPlaidAccountStatus } from 'api/resources/plaidAccounts';
import { PlaidButton } from '../../PlaidConnect/partials/PlaidButton';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import { useGetPayTypes } from 'api/hooks/useGetPayTypes';
import BaseDropdown from 'legacy/app/components/dropdowns/BaseDropdown';
import { IMappedPayType } from 'api/resources/payTypes';
import { usePatchPlaidAccount } from 'api/hooks/usePatchPlaidAccount';
import URI from 'legacy/defaults/RoutesDefault';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

export const PlaidAccountEdit = () => {
	const { id } = useParams();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const { mutateAsync: editAccount, isLoading: isEditing } =
		usePatchPlaidAccount();
	const { data: accounts, isLoading, error } = useGetPlaidAccounts();
	const [defaultAccountsNumber, setDefaultAccountsNumber] =
		useState<IMappedPayType | null>(null);

	const {
		data: payTypes,
		isLoading: isFetchingPayTypes,
		error: payTypesError,
	} = useGetPayTypes();

	const isFetchingLinkToken = useIsFetching({
		queryKey: [ECacheKeys.PlaidToken],
	});

	const plaidAccount = useMemo(
		() => accounts?.find((account) => account.id === Number(id)),
		[id, accounts]
	);

	useEffect(() => {
		if (error || payTypesError) {
			displayAlertError(
				'There was an error loading the account details. Please try again later.'
			);
		}
	}, [error, payTypesError]);

	useEffect(() => {
		if (payTypes && plaidAccount) {
			const selectedPayType = payTypes.find((payType) =>
				plaidAccount.payTypeAccountId
					? payType.id === plaidAccount.payTypeAccountId
					: payType.accountNumber === plaidAccount.defaultAccountsNumber
			);
			setDefaultAccountsNumber(selectedPayType || null);
		}
	}, [payTypes, plaidAccount]);

	const onSubmit = async (isDelete?: boolean) => {
		try {
			setShowDeleteModal(false);
			const body = isDelete
				? {
						id: Number(id),
						newStatus: 'Deleted',
				  }
				: {
						id: Number(id),
						defaultAccountsNumber: defaultAccountsNumber?.id
							? null
							: defaultAccountsNumber?.accountNumber,
						payTypeAccountId: defaultAccountsNumber?.id || null,
				  };
			await editAccount(body);
			await queryClient.invalidateQueries({
				queryKey: [ECacheKeys.PlaidAccounts],
			});
			navigate(URI.accountSettings.plaidAccounts);
			displayAlertSuccess(
				isDelete
					? 'Account deleted successfully!'
					: 'Account details updated successfully!'
			);
		} catch {
			const responseError = error as {
				response?: { data?: { userError?: string } };
			};
			displayAlertError(
				responseError.response?.data?.userError ||
					'There was an error updating the account. Please try again.'
			);
		}
	};

	if (isLoading || isFetchingPayTypes) {
		return <Spinner isChild />;
	}

	const disableSaveButton =
		!!isFetchingLinkToken ||
		isEditing ||
		defaultAccountsNumber === null ||
		defaultAccountsNumber?.accountNumber ===
			plaidAccount?.defaultAccountsNumber ||
		defaultAccountsNumber?.id === plaidAccount?.payTypeAccountId;

	return plaidAccount && payTypes ? (
		<div className="tw-flex tw-flex-col tw-h-screen">
			<ConfirmationModal
				title="Warning"
				show={showDeleteModal}
				toggleModal={() => setShowDeleteModal(false)}
				confirmAction={() => onSubmit(true)}
				message="This will permanently delete this account."
				labelOK="OK"
				labelCancel="Cancel"
			/>
			<IndividualAccountHeader
				accountName={plaidAccount.name}
				disableSaveButton={disableSaveButton}
				onSave={() => onSubmit()}
			/>
			<div className="tw-items-center  tw-mt-8 tw-pl-12 tw-gap-8 tw-gap-y-8 tw-grid tw-grid-cols-[100px_400px] tw-max-w-[1200px]">
				<Label label="Name" />
				<Form.Control disabled value={plaidAccount.name} />
				<Label label="Account No." />
				<Form.Control disabled value={plaidAccount.maskedNumber} />
				<Label label="Account Type" />
				<Form.Control disabled value={plaidAccount.type || ''} />
				<Label label="Status" />
				{plaidAccount.status === EPlaidAccountStatus.NEEDREAUTHENTICATION ? (
					<div>
						<PlaidButton
							className="tw-ml-[130px]"
							accountId={plaidAccount.id}
							buttonLabel="Reconnect"
						/>
					</div>
				) : (
					<Form.Control disabled value={plaidAccount.status} />
				)}
				<Label label="Pay With" />
				<BaseDropdown
					value={defaultAccountsNumber}
					options={payTypes}
					onChange={(newPayType) =>
						setDefaultAccountsNumber(newPayType as IMappedPayType)
					}
				/>
			</div>
			<div className="tw-p-8 bg-ivory gradient light tw-mt-auto">
				<Button
					onClick={() => setShowDeleteModal(true)}
					disabled={!!isFetchingLinkToken || isEditing}
					variant="danger"
				>
					Delete
				</Button>
			</div>
		</div>
	) : null;
};

PlaidAccountEdit.displayName = 'PlaidAccountEdit';
