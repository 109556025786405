import React, { useState } from 'react';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { formatDate } from 'legacy/helpers/Date';
import {
	TEditFinanceCharge,
	TFinanceChargeAccount,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeTypes';
import { Form, Table, Button } from 'react-bootstrap';
import {
	formatChargesList,
	getAcctNameFromString,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeUtils';
import { IJournalEntry } from 'api/resources/financeCharges';
import { usePostFinanceCharges } from 'api/hooks/usePostFinanceCharges';
import dayjs from 'dayjs';
import { formatMoney } from '../../utils';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';

type TFinanceChargeOverviewProps = Omit<TEditFinanceCharge, 'newTxnum'> & {
	account: TFinanceChargeAccount;
	showModal: boolean;
	onCancel: () => void;
	onCreateEntry: () => void;
	onUpdateEntry: (account: TFinanceChargeAccount, amount: number) => void;
	onDeleteEntry: (accountId: TFinanceChargeAccount) => void;
	onVoidEntireCharge: () => void;
	onSubmit: () => void;
	statementDate: string;
	journalEntries: IJournalEntry[];
	canVoid: boolean;
	isDirty: boolean;
	savedDescription: string;
};

export const FinanceChargeOverview = ({
	account,
	showModal,
	onCancel,
	onCreateEntry,
	onUpdateEntry,
	onDeleteEntry,
	onVoidEntireCharge,
	onSubmit,
	statementDate,
	journalEntries,
	canVoid,
	isDirty,
	savedDescription,
}: TFinanceChargeOverviewProps) => {
	const [description, setDescription] = useState(
		savedDescription || `Srv/Fin Charges ${formatDate(statementDate)}`
	);

	const { mutate } = usePostFinanceCharges();

	return (
		<ConfirmationModal
			confirmAction={async () => {
				const formattedDate = dayjs(statementDate).format('YYYY-MM-DD');
				const payload = {
					description,
					isCheckbook: false,
					account: account.value,
					statementEndingDate: formattedDate,
					financeChargesList: formatChargesList(journalEntries),
				};

				mutate(payload);

				onSubmit();
			}}
			disabledOK={!isDirty}
			show={showModal}
			onCancel={onCancel}
			title="Service/Finance Charges"
			labelOK="OK"
			okBtnStyle="primary"
		>
			<div className="tw-grid tw-grid-flow-row tw-gap-4">
				<div className="tw-grid tw-grid-cols-[1fr_auto] tw-gap-2">
					<div className="tw-flex tw-gap-1 tw-items-center">
						<Form.Label>Description:</Form.Label>
						<Form.Control
							type="text"
							name="description"
							maxLength={40}
							onChange={(e) => setDescription(e.target.value)}
							value={description}
						/>
					</div>
					<Button
						className="tw-w-fit tw-self-end"
						onClick={() => onCreateEntry()}
					>
						<i className="ri-add-line ri-lg"></i> Add
					</Button>
				</div>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th className="tw-text-start tw-w-fit">Acct No.</th>
							<th className="tw-text-center tw-w-fit">Account Name</th>
							<th className="tw-text-center tw-w-auto">Amount</th>
							<th className="tw-text-center tw-w-4">Action</th>
						</tr>
					</thead>
					<tbody>
						{journalEntries?.map((entry) => {
							return (
								<tr key={`entry-${entry.account}`}>
									<td className="tw-align-middle">
										<a
											title="Edit entry"
											className="tw-text-black tw-no-underline tw-cursor-pointer"
											onClick={() => {
												onUpdateEntry(
													{
														value: entry.account,
														label: entry.accountName as string,
													},
													entry.damt || 0
												);
											}}
										>
											<span className="tw-underline">{entry.account}</span>{' '}
											<i className="ri-pencil-fill fsx-16 tw-text-green-700"></i>
										</a>
									</td>
									<td className="tw-align-middle w-full">
										{getAcctNameFromString(entry?.accountName || '')}
									</td>
									<td className="tw-text-end">{formatMoney(entry.damt)}</td>
									<td className="tw-align-middle tw-text-center">
										{journalEntries.length > 1 && (
											<BaseButton
												onClick={() =>
													onDeleteEntry({
														value: entry.account,
														label: entry.accountName as string,
													})
												}
												text={
													<i className="ri-close-line fsx-16 tw-text-red-500"></i>
												}
											/>
										)}
									</td>
								</tr>
							);
						})}
						{journalEntries.length === 0 && (
							<tr>
								<td colSpan={4} className="tw-text-center">
									No entries
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<div className="tw-grid tw-grid-flow-col">
					<div className="tw-flex tw-items-center tw-justify-start">
						{(canVoid || journalEntries.length === 1) && (
							<Button
								onClick={() => onVoidEntireCharge()}
								variant="outline-danger"
							>
								Void Entire <br /> Charge
							</Button>
						)}
					</div>
					<div className="tw-flex tw-items-center tw-justify-end tw-pr-16">
						<p>
							Total Service/Finance Charges:{' '}
							{formatMoney(
								journalEntries.reduce(
									(accumulator, currentValue) =>
										accumulator + (currentValue?.damt ?? 0),
									0
								)
							)}
						</p>
					</div>
				</div>
			</div>
		</ConfirmationModal>
	);
};

FinanceChargeOverview.displayName = 'FinanceChargeOverview';
