import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Dropdown,
	Form,
	Row,
	Table,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import URI from '../../../../defaults/RoutesDefault';
import {
	formatDate,
	formatFilterDate,
	formatFiscalMonth,
} from '../../../../helpers/Date';
import { WithRouter } from '../../../../helpers/Router';
import { ApiService } from '../../../../lib/api/HttpService';
import Header from '../../../components/Header';
import ListFilter from '../../../components/ListFilter';
import ThreadComponent from '../../ThreadComponent';
import dayjs from 'dayjs';
import { displayAlert } from '../../../../utilities/Response';
import { getCookie } from '../../../../utilities/Auth';
import CheckbookTransferModal from './CheckbookTransferModal';
import { setLocalStorage } from '../../../../utilities/LocalStorage';
import { debounce, uniq, compact, isEmpty, isEqual, tail } from 'lodash';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { isFiltered } from '../../../../helpers/Util';
import { DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import CheckbookVoidModal from '../../modal/CheckbookVoidModal';
import { currencyFormat } from '../../../../helpers/Number';
import SecureDropdownItem from '../../../../app/components/security/SecureDropdownItem';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import { renderSecureContent } from '../../../../utilities/modules/SecureContent';
import { CheckbookHeader } from 'legacy/templates/modules/accounts-payable/checkbook/Checkbook/CheckbookHeader';
import cn from 'classnames';

class CheckbookList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			checks: {},
			totalChecked: 0,
			data: [],
			startDate: new Date(),
			dataIsLoaded: false,
			accounts: [],
			checkbookData: {
				cashAccount: '',
				date: new Date(),
				showVoids: false,
			},
			checkbooks: [],
			showTransferModal: false,
			searchProperties: {},
			sortProperty: 'checknum asc',
			showTableSearch: false,
			enableVoidUnvoid: false,
			voidValue: 'none',
			voids: [],
			selectedItems: [],
			showVoidModal: false,
			page: 1,
			pageSize: { value: 10, label: '10' },
		};

		this.api = new ApiService();
		this.availablePageSize = [
			{ value: 10, label: '10' },
			{ value: 20, label: '20' },
			{ value: 50, label: '50' },
			{ value: 75, label: '75' },
			{ value: 100, label: '100' },
		];
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	componentInit() {
		this.setTitle('Checkbook').setActionBar(true);
	}

	async componentDidMount() {
		const accounts = await this.api.getSummaryGLAccounts(
			`?$filter=fixedtype eq 101`
		);

		this.setState(
			{
				accounts: accounts.map((account) => {
					return {
						value: account.key,
						label: `[${account.key}] ${account.value}`,
					};
				}),
				checkbookData: {
					cashAccount: accounts[0].key,
					date: dayjs(new Date().toString()).format('MM/YYYY'),
					showVoids: false,
				},
				sortProperty: 'date desc',
			},
			this.getCheckbookList
		);
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
		let { checkbookData } = this.state;
		checkbookData.date = dayjs(date.toString()).format('MM/YYYY');
		this.setState(
			{
				checkbookData,
				dataIsLoaded: false,
				page: 1,
			},
			this.getCheckbookList
		);
	};

	handleSearchDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			this.changePageHandler
		);
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		let { checkbookData } = this.state;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name ?? e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		checkbookData[key] = value;
		this.setState(
			{
				checkbookData,
				...{ [key]: value },
				dataIsLoaded: false,
				page: 1,
			},
			this.getCheckbookList
		);

		if (key == 'showVoids') {
			this.setState({
				totalChecked: 0,
				voids: [],
				enableVoidUnvoid: false,
				selectedItems: [],
			});
		}
	};
	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);

		if (name !== 'pageSize') {
			this.fs.setFilter(name, e);
		}
	};

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');

		// Set the new state.
		this.setState((prevState) => {
			let totalChecked = 0;
			prevState.checks[indx] = e.target.checked;

			if (prevState.checks) {
				/* eslint-disable no-unused-vars */
				for (const [index, value] of Object.entries(prevState.checks)) {
					if (value) {
						totalChecked++;
					}
				}
				/* eslint-enable no-unused-vars */
			}

			const currentItem = this.state.data[indx];

			if (e.target.checked) {
				prevState.selectedItems[indx] = currentItem;
			} else {
				prevState.selectedItems = prevState.selectedItems.filter(
					(_, index) => index !== Number(indx)
				);
			}

			prevState.totalChecked = totalChecked;

			if (totalChecked > 0) {
				document.getElementById('inline-check-th-0').checked = true;
			} else {
				document.getElementById('inline-check-th-0').checked = false;
			}

			prevState.enableVoidUnvoid = this.showVoidUnvoid();
			prevState.voidValue = this.checkVoidValues();

			return prevState;
		});
	};

	showVoidUnvoid = () => {
		let allOfType4 = false;

		for (const item of this.state.selectedItems) {
			allOfType4 = true;
			if (item) {
				if (item.type !== 4) {
					allOfType4 = false;
					break;
				}
			}
		}

		return allOfType4;
	};

	checkVoidValues = () => {
		let hasTrue = false;
		let hasFalse = false;

		for (const item of this.state.selectedItems) {
			if (item) {
				if (item.void === true) {
					hasTrue = true;
				} else if (item.void === false) {
					hasFalse = true;
				}
			}
		}

		if (hasTrue && !hasFalse) {
			return 'Unvoid';
		}

		if (!hasTrue && hasFalse) {
			return 'Void';
		}

		if (hasTrue && hasFalse) {
			return 'Void/Unvoid';
		}

		return 'none';
	};

	handleSelectAllChecks = (e) => {
		// Get the target menu.
		const isChecked = e.target.checked;

		this.setState((prevState) => {
			if (isChecked) {
				if (this.state.totalChecked < this.state.data.length) {
					this.state.data.map((item, i) => {
						prevState.checks[i] = true;
						document.getElementById(
							'chk-projectview-items-' + i
						).checked = true;

						prevState.selectedItems[i] = item;

						return null;
					});
				}
			} else {
				prevState.checks = {};
				prevState.totalChecked = 0;
				prevState.voids = [];
				prevState.enableVoidUnvoid = false;
				prevState.selectedItems = [];

				const checks = document.querySelectorAll(
					'.chk-projectview-items-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}
			}

			prevState.enableVoidUnvoid = this.showVoidUnvoid();
			prevState.voidValue = this.checkVoidValues();

			return prevState;
		});
	};

	checkHasTheSameValues(array) {
		const nonNullValues = compact(array);
		if (isEmpty(nonNullValues)) {
			return true;
		}
		return isEqual(nonNullValues[0], tail(nonNullValues));
	}

	async getCheckbookList() {
		const { checkbookData } = this.state;
		await this.api.createCheckbookGrids(checkbookData).catch((error) => {
			if (error.response.data && error.response.data.userError) {
				displayAlert('danger', error.response.data.userError);
			} else {
				displayAlert('danger', 'Failed fetching data.');
			}
		});

		const dateRange = this.getDateRange();

		let filter = `?$filter=`;
		filter += !checkbookData.showVoids ? 'void eq false' : '';
		filter += filter.slice(-1) !== '=' ? ' and ' : '';
		filter += `date ge ${dateRange.firstDay} and date lt ${dateRange.lastDay}`;
		filter += ` and usercode eq ${getCookie('dmUsercode')}`;
		filter += `${this.buildFilters()}`;

		const checkbooks = await this.api.getCheckbooks(filter);
		this.renderData(checkbooks);
	}

	getDateRange() {
		let date = this.state.startDate;
		let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		let lastDay = new Date(date.getFullYear(), date.getMonth() + 1);
		firstDay = dayjs(firstDay.toString()).format('YYYY-MM-DD');
		lastDay = dayjs(lastDay.toString()).format('YYYY-MM-DD');
		return { firstDay, lastDay };
	}

	getLinkByType(item) {
		switch (item.type) {
			case 2:
				return (
					<Link
						to={URI.accountsPayable.checkbook.depositDetail}
						className="text-charcoal hover-view-icon"
						onClickCapture={() => this.setStorageData(item)}
					>
						{formatDate(item.date)}
					</Link>
				);
			case 4:
				return (
					<Link
						to={URI.accountsPayable.checkbook.checkDetail}
						className="text-charcoal hover-view-icon"
						onClickCapture={() => this.setStorageData(item)}
					>
						{formatDate(item.date)}
					</Link>
				);
			case 5:
				return (
					<Link
						to={URI.accountsPayable.checkbook.journalEntryDetail}
						className="text-charcoal hover-view-icon"
						onClickCapture={() => this.setStorageData(item)}
					>
						{formatDate(item.date)}
					</Link>
				);
			default:
				return <>{formatDate(item.date)}</>;
		}
	}

	setStorageData(item) {
		setLocalStorage('checkbook_detail', item, true);
		setLocalStorage('account_data', this.state.checkbookData.cashAccount);
		setLocalStorage('accountn_data', JSON.stringify(this.state.accounts));
	}

	buildFilters(currentPage) {
		let queryString = `&$top=${this.state.pageSize.value + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize.value
		}`;
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.max) {
				if (property.type === 'number' || property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		const filtersQuery =
			filters.length > 0 ? ` and ${filters.join(' and ')}` : '';
		queryString += `&${filtersQuery}`;

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.setState({
			dataIsLoaded: false,
		});

		if (this.state.dataIsLoaded) {
			this.setState(
				{
					page: page,
				},
				() => {
					this.getCheckbookList();
				}
			);
		}
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			this.changePageHandler
		);
	};

	handleVoidUnvoidConfirmation = () => {
		this.setState({
			showVoidModal: true,
		});
	};

	hideVoidUnvoidConfirmation = () => {
		this.setState({
			showVoidModal: false,
		});
	};

	handleVoidUnvoid = async (fiscalMonth) => {
		let hasError = false;

		for (const item of this.state.selectedItems) {
			if (item) {
				const payload = {
					checkNum: item.checknum,
					checkDt: item.date,
					cashAcctNum:
						this.state.cashAccount ?? this.state.checkbookData['cashAccount'],
					voidFMo: formatFiscalMonth(fiscalMonth),
					isUndoVoid: item.void,
				};

				await this.api.voidUnvoidCheckbook(payload).catch((error) => {
					hasError = true;
					displayAlert('danger', error.response.data.userError);
				});
			}
		}

		if (!hasError) {
			let successMessage = '';
			if (this.state.voidValue == 'Void' || this.state.voidValue == 'Unvoid') {
				successMessage = `${this.state.voidValue}ed`;
			} else {
				successMessage = 'Voided/Unvoided';
			}
			displayAlert('success', `Successfully ${successMessage} entry`);

			setTimeout(() => {
				document.getElementById('inline-check-th-0').checked = false;
				const checks = document.querySelectorAll(
					'.chk-projectview-items-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}

				this.setState(
					{
						checks: [],
						totalChecked: 0,
						selectedItems: [],
						dataIsLoaded: false,
						page: 1,
					},
					() => {
						this.getCheckbookList();
					}
				);
			}, 2000);
		}

		this.hideVoidUnvoidConfirmation();
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						self.setState(
							{
								sortProperty: `${_e.attributes['data-field'].value} ${
									hasClass('desc', _e) ? 'asc' : 'desc'
								}`,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.data.length <= this.state.pageSize.value}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key={`${Math.floor(Math.random() * 1000)}-min`}
					options={this.availablePageSize}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	renderFilters() {
		return (
			<>
				<ListFilter className="checkbook-list-filter">
					<ListFilter.Fields md={12} lg={12} xl={9} className="width-25">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Account</strong>
							</Form.Label>
							<Select
								key={`${Math.floor(Math.random() * 10000)}-min`}
								menuPortalTarget={document.body}
								styles={{
									menuPortal: (base) => ({
										...base,
										zIndex: 9999,
									}),
									container: (provided) => ({
										...provided,
										width: '100%',
									}),
								}}
								options={this.state.accounts}
								placeholder="Please select"
								name="cashAccount"
								value={
									this.state.accounts.filter((account) => {
										return account.value === this.state.cashAccount;
									})[0] ?? this.state.accounts[0]
								}
								onChange={this.handleChange}
							/>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Date</strong>
							</Form.Label>
							<div className="react-select-header">
								<DatePicker
									selected={this.state.startDate}
									onChange={this.handleDateChange}
									className="form-control form-control-sm"
									dateFormat="MM/yyyy"
									placeholderText="Select"
									showMonthYearPicker
								/>
							</div>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="d-block d-md-none">
								<strong></strong>
							</Form.Label>
							<Form.Check
								inline
								label="Show Voids"
								name="showVoids"
								type="checkbox"
								id={`inline-chk-show-all`}
								onChange={this.handleChange}
							/>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						md={12}
						lg={12}
						xl={3}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-start justify-content-md-end"
					>
						{/* <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button> */}
						{/* <Button as={Link} to={URI.accountsPayable.checkbook.reconcile} variant="primary" size='sm' className='btn-icon ms-3'>Reconcile</Button> */}
						{/* </ListFilter.Action> */}
						<ListFilter.Action className="ms-auto ms-md-0">
							<Dropdown className="d-flex justify-content-end ms-3" align="end">
								<Dropdown.Toggle
									id="dropdown-autoclose-true"
									variant="ivory"
									size="sm"
								>
									Actions
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<SecureDropdownItem
										attributeNo={59}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec2}
										href=""
										onClick={() =>
											this.setState((prev) => (prev.showTransferModal = true))
										}
									>
										<i className="ri-file-transfer-line"></i> Transfer
									</SecureDropdownItem>
									{this.state.enableVoidUnvoid ? (
										<Dropdown.Item
											href=""
											onClick={this.handleVoidUnvoidConfirmation}
										>
											<i
												className={
													this.state.voidValue == 'Void/Unvoid' ||
													this.state.voidValue == 'Void'
														? 'ri-eye-fill'
														: 'ri-eye-off-line'
												}
											></i>{' '}
											{this.state.voidValue}
										</Dropdown.Item>
									) : (
										''
									)}
									{/* <Dropdown.Item href="">
                                        <i className="ri-file-chart-line"></i>{' '}
                                        Create Report
                                    </Dropdown.Item> */}
								</Dropdown.Menu>
							</Dropdown>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		return (
			<>
				<CheckbookHeader />
				<div className="content-padding has-action-bar sticky-container">
					<Container fluid className="px-0">
						{renderSecureContent(
							<Row>
								<Col sm="12">
									{/* Filter */}
									{this.renderFilters()}

									<div className="table-gradient">
										<Table striped responsive className="a-table">
											<thead>
												<tr className="a-table-heading">
													<th align="middle" className="w-90px">
														<div className="d-flex justify-content-center">
															<Form.Check
																inline
																label=""
																name={`inline-check-th-0`}
																type="checkbox"
																data-id={`th-0`}
																id={`inline-check-th-0`}
																defaultChecked={
																	this.state.totalChecked > 0 ? true : false
																}
																className={`${
																	this.state.totalChecked > 0 &&
																	this.state.totalChecked <
																		this.state.data.length
																		? 'line'
																		: ''
																}`}
																onClick={this.handleSelectAllChecks}
															/>
														</div>
													</th>
													<th className="mw-80px">Date</th>
													<th className="mw-110px">Check No.</th>
													<th className="tw-text-center">Cleared</th>
													<th>Vendor Code</th>
													<th className="description">Description</th>
													<th className="mw-120px">Payment (-)</th>
													<th className="mw-110px">Deposit (+)</th>
													<th>Balance</th>
												</tr>
												<tr
													className={`a-table-search-fields ${
														this.state.showTableSearch ? '' : 'd-none'
													}`}
												>
													<th></th>
													<th>
														<DateRangePicker
															style={{
																minWidth: '200px',
															}}
															placement="auto"
															placeholder="Select date"
															format="MM/dd/yyyy"
															onChange={this.handleSearchDateChange.bind(
																this,
																'date'
															)}
															onClean={this.handleSearchDateChange.bind(
																this,
																'date'
															)}
															ranges={[
																{
																	label: 'today',
																	value: [
																		startOfDay(new Date()),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'yesterday',
																	value: [
																		startOfDay(addDays(new Date(), -1)),
																		endOfDay(addDays(new Date(), -1)),
																	],
																},
																{
																	label: 'last7Days',
																	value: [
																		startOfDay(subDays(new Date(), 6)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'Last 30 Days',
																	value: [
																		startOfDay(subDays(new Date(), 30)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'This month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()),
																				1
																			)
																		),
																		endOfDay(lastDayOfMonth(new Date())),
																	],
																},
																{
																	label: 'Last month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()) - 1,
																				1
																			)
																		),
																		endOfDay(
																			lastDayOfMonth(
																				new Date(
																					getYear(new Date()),
																					getMonth(new Date()) - 1,
																					1
																				)
																			)
																		),
																	],
																},
															]}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="checknum"
															onChange={this.handleSearch}
														/>
													</th>
													<th></th>
													<th>
														<Form.Control
															type="text"
															data-field="supplier"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="payeen"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="payment"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="deposit"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="balance"
															onChange={this.handleSearch}
														/>
													</th>
												</tr>
											</thead>
											<tbody>
												{!this.state.dataIsLoaded
													? showLoading()
													: this.state.data.length === 0 &&
													  isFiltered(this.state.searchProperties)
													? showEmpty()
													: this.state.data
															.slice(0, this.state.pageSize.value)
															.map((item, i) => (
																<tr
																	key={i}
																	data-key={i}
																	className={
																		this.state.checks[i] ? `active` : ''
																	}
																>
																	<td>
																		<div className="d-flex justify-content-center">
																			<Form.Check
																				label=""
																				name={`group-` + i}
																				type="checkbox"
																				data-id={i}
																				data-void={item.void}
																				id={`chk-projectview-items-` + i}
																				className="chk-projectview-items-item"
																				defaultValue={i}
																				onClick={this.handleChecks}
																			/>
																		</div>
																	</td>
																	<td>{this.getLinkByType(item)}</td>
																	<td>{item.checknum}</td>
																	<td className="tw-text-center">
																		<span
																			className={cn({
																				'dot-primary': item.cleared,
																				'dot-primary-red': !item.cleared,
																			})}
																		></span>
																	</td>
																	<td>{item.supplier}</td>
																	<td>{item.payeen}</td>
																	<td>{currencyFormat(item.payment, '$')}</td>
																	<td>{currencyFormat(item.deposit, '$')}</td>
																	<td>{currencyFormat(item.balance, '$')}</td>
																</tr>
															))}
											</tbody>
										</Table>
										{this.state.data.length > 0 ? this.renderPagination() : ''}
									</div>
								</Col>
							</Row>
						)(59, SECURITY_ATTRIBUTE_TYPES.DenyAccess)}
					</Container>
					<CheckbookTransferModal
						show={this.state.showTransferModal}
						hideModal={() =>
							this.setState((prev) => (prev.showTransferModal = false))
						}
					/>
					{this.state.showVoidModal && (
						<CheckbookVoidModal
							hideModal={this.hideVoidUnvoidConfirmation}
							handleSave={this.handleVoidUnvoid}
						/>
					)}
				</div>
			</>
		);
	}
}

export default WithRouter(CheckbookList);
