import URI from 'legacy/defaults/RoutesDefault';
import { Form } from 'react-bootstrap';
import { TSelectedAddOption } from './ExpandablePanel';

const options = [
	{
		url: URI.accountsPayable.vendorInvoice.addMisc,
		id: 'company-expense',
		element: (
			<label
				htmlFor="company-expense"
				className="tw-text-base tw-font-normal tw-flex tw-gap-1"
			>
				This is a
				<span className="tw-text-[#008b6d] tw-font-normal">Company wide</span>
				expense.
			</label>
		),
	},
	{
		url: URI.accountsPayable.vendorInvoice.addMisc,
		id: 'project-expense',
		element: (
			<label
				htmlFor="project-expense"
				className="tw-text-base tw-font-normal tw-flex tw-gap-1"
			>
				This is a
				<span className="tw-text-[#008b6d] tw-font-normal">
					Project expense
				</span>
				without a purchase order.
			</label>
		),
	},
	{
		url: URI.accountsPayable.vendorInvoice.add,
		id: 'vendor-invoice',
		element: (
			<label
				htmlFor="vendor-invoice"
				className="tw-text-base tw-font-normal tw-flex tw-gap-1"
			>
				This is a
				<span className="tw-text-[#008b6d] tw-font-normal">
					full payment of a vendor invoice
				</span>
			</label>
		),
	},
	{
		url: URI.accountsPayable.vendorInvoice.addDeposit,
		id: 'deposit',
		element: (
			<label
				htmlFor="Deposit"
				className="tw-text-base tw-font-normal tw-flex tw-gap-1"
			>
				This is a
				<span className="tw-text-[#008b6d] tw-font-normal">deposit</span>
				towards a purchase order.
			</label>
		),
	},
];

export const ExpandablePanelAddOption = ({
	setSelectedAddOption,
	selectedAddOption,
}: {
	selectedAddOption: TSelectedAddOption;
	setSelectedAddOption: (value: TSelectedAddOption) => void;
}) => {
	return (
		<div>
			<p className="tw-text-base">What type of transaction is this?</p>
			<div className="tw-flex tw-mt-4 tw-pl-4 tw-flex-col tw-gap-4">
				{options.map((option) => (
					<div className="tw-flex tw-gap-2" key={option.id}>
						<Form.Check
							inline
							onChange={() =>
								setSelectedAddOption({
									id: option.id,
									url: option.url,
								})
							}
							checked={selectedAddOption?.id === option.id}
							type="radio"
							label=""
							id={option.id}
						/>
						{option.element}
					</div>
				))}
			</div>
		</div>
	);
};
ExpandablePanelAddOption.displayName = 'ExpandablePanelAddOption';
