import React from 'react';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import { formatReconciliationCurrency } from 'legacy/utilities/formatReconciliationCurrency';
import { CheckbookReconcileTotalsItem } from './CheckbookReconcileTotalsItem';

const CheckbookReconcileTotals = () => {
	const { totals, checkbook } = useCheckbookReconcile();

	return (
		<div className="tw-grid tw-grid-cols-[1fr_16px_1fr_16px_1fr_16px_1fr_1fr_1fr] tw-gap-4 tw-items-stretch tw-justify-start">
			<CheckbookReconcileTotalsItem
				heading="Statement Balance"
				value={formatReconciliationCurrency(checkbook?.endingBalance ?? 0)}
			/>
			<span className="tw-text-base tw-self-center">
				<i className="ri-add-line"></i>
			</span>
			<CheckbookReconcileTotalsItem
				heading="Deposits in Transit"
				value={formatReconciliationCurrency(totals?.depositsInTransit ?? 0)}
			/>
			<span className="tw-text-base tw-self-center">
				<i className="ri-subtract-line"></i>
			</span>
			<CheckbookReconcileTotalsItem
				heading="Outstanding Checks"
				value={formatReconciliationCurrency(totals?.outstandingChecks ?? 0)}
			/>
			<span className="tw-text-base tw-self-center">
				<i className="ri-equal-line"></i>
			</span>
			<CheckbookReconcileTotalsItem
				heading="Balance"
				value={formatReconciliationCurrency(totals?.balance ?? 0)}
			/>

			<CheckbookReconcileTotalsItem
				heading="Computer Balance"
				value={formatReconciliationCurrency(totals?.computerBalance ?? 0)}
			/>
			<CheckbookReconcileTotalsItem
				heading="Difference"
				value={formatReconciliationCurrency(totals?.difference ?? 0)}
				borderClass={
					totals
						? totals.difference === 0
							? 'tw-border-green-500'
							: 'tw-border-red-500'
						: ''
				}
			/>
		</div>
	);
};

CheckbookReconcileTotals.displayName = 'CheckbookReconcileTotals';

export { CheckbookReconcileTotals };
