import React from 'react';
import { Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { WithRouter } from 'legacy/helpers/Router';
import { ApiService } from 'legacy/lib/api/HttpService';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
} from 'legacy/utilities/Response';
import ThreadComponent from '../ThreadComponent';
import { getCookie } from '../../../utilities/Auth';
import { isEmpty } from 'lodash';
import { removeSpaces } from 'legacy/utilities/String';
import { isEmail } from 'legacy/utilities/Validation';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import MSG from 'legacy/defaults/Message';

class SendProposalModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			hasFile: false,
			emailAttributes: {
				to: '',
				cc: '',
				bcc: '',
				subject: '',
				message: '',
				attachment: [],
				includeUserSignature: true,
			},
			company: {
				name: '',
			},
			clientPortal: this.props.project.clientPortalEnabled,
		};

		this.api = new ApiService();
	}

	componentDidMount = async () => {
		try {
			const companyDetails = await this.api.getCompany();

			this.setState({ company: companyDetails });
			// eslint-disable-next-line no-empty
		} catch (error) {}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {
			this.setState({
				hasFile: false,
				emailAttributes: {
					to: this.props.sendProposalData?.address[0]?.email,
					cc: '',
					bcc: '',
					subject: '',
					message: '',
					attachment: [],
					includeUserSignature: true,
				},
				clientPortal: this.props.project.clientPortalEnabled,
			});
		}
	}

	onFileChange = (e) => {
		this.setState((prev) => (prev.hasFile = e.target.value ? true : false));
		this.setState((prevState) => ({
			emailAttributes: {
				...prevState.emailAttributes,
				attachment: e.target.files,
			},
		}));
	};

	onChange = (e) => {
		this.setState((prevState) => ({
			emailAttributes: {
				...prevState.emailAttributes,
				[e.target.name]: e.target.value,
			},
		}));
	};

	handleChecked = (e) => {
		this.setState((prevState) => ({
			emailAttributes: {
				...prevState.emailAttributes,
				[e.target.name]: e.target.checked,
			},
		}));
	};

	renderMessage = () => {
		return `Dear ${
			this.props.sendProposalData?.address[0]?.contact ?? ''
		}\n\nThis following new Proposal(s) have been added to your account:\n\n${this.props.message.join(
			'\n'
		)}`;
	};

	isValid() {
		const { emailAttributes } = this.state;
		if (isEmpty(removeSpaces(emailAttributes.to))) {
			displayAlertError('Please enter a valid email address');
			return false;
		}

		return true;
	}

	handleSave = async () => {
		try {
			let files = [];

			if (!this.isValid()) {
				return;
			}

			displayAlertLoader('Sending email<span class="dot-load"></span>');
			const { emailAttributes } = this.state;

			if (emailAttributes.attachment.length) {
				let uploadedFile = await this.api.saveFile({
					file: emailAttributes.attachment[0],
					FileType: 'other',
				});

				files.push(uploadedFile.id);
			}

			let user = await this.api.getUserData(
				`?$filter=usercode eq ${getCookie('dmUsercode')}`
			);

			await this.api.postJson('notifications', {
				notificationTypeId: 1,
				emailData: {
					to: emailAttributes.to.split(';').map((em) => em.trim()) || [
						this.props.sendProposalData?.address[0]?.email,
					],

					cc:
						emailAttributes.cc == ''
							? []
							: emailAttributes.cc.split(';').map((em) => em.trim()),
					bcc:
						emailAttributes.bcc == ''
							? []
							: emailAttributes.bcc.split(';').map((em) => em.trim()),
					subject:
						emailAttributes.subject ||
						`Proposal from ${this.state.company.name}`,
					message: emailAttributes.message || this.renderMessage(),
				},
				...(files.length && { files }),
				publicMappingBatchToken: this.props.sendProposalData.token,
				includeUserSignature: emailAttributes.includeUserSignature,
			});

			displayAlert('success', 'Email submitted successfully');

			this.props.hideModal();
			this.props.refreshData?.();
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}
	};

	getValidEmails(values) {
		if (!isEmpty(values)) {
			values = values
				.replace(/\s/gi, ';')
				.replace(/\//g, ';')
				.replace(',', ' ');
			values = values.split(';');

			values = values
				.map((email) => {
					return removeSpaces(email);
				})
				.filter((email) => {
					if (isEmail(removeSpaces(email))) {
						return true;
					} else {
						!isEmpty(email) &&
							displayAlertError(
								`<u>${email}</u>&nbsp;is not a valid email address`
							);
						return false;
					}
				});

			return values.join('; ');
		}

		return '';
	}

	resetFields() {
		const emailAttributes = this.state.emailAttributes;
		emailAttributes.to = '';
		emailAttributes.cc = '';
		emailAttributes.bcc = '';
		this.setState({
			emailAttributes,
		});
	}

	launchClientPortal = async () => {
		try {
			const response = await this.api.postClientPortalToken(
				this.props.project.id
			);

			if (response.isOk && response.token) {
				const portalUrl = `${process.env.REACT_APP_CLIENT_PORTAL_PRO_BASE_URL}/proposals?token=${response.token}`;
				window.open(portalUrl, '_blank');
				return { success: true, portalUrl };
			} else {
				displayAlertError(MSG.error.clientPortalToken.open);
				return { success: false };
			}
		} catch (error) {
			displayAlertError(MSG.error.clientPortalToken.generate);
			return { success: false, error };
		}
	};

	render() {
		return (
			<Modal
				size="lg"
				show={this.props.show}
				onHide={() => {
					this.resetFields();
					this.props.hideModal();
				}}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal  fixed-footer"
				backdrop="static"
			>
				<Modal.Body>
					<div className="p-5 mt-5 mx-auto" style={{ maxWidth: '600px' }}>
						<h4 className="ff-type-medium">Send Proposal Email</h4>
						<div className="form-fields px-4 mt-5">
							<Form.Group as={Row} className="mb-3">
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
									for="txt-email-to"
								>
									To:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										defaultValue={
											this.state.emailAttributes.to ||
											this.props.sendProposalData?.address[0]?.email
										}
										name="to"
										id="txt-email-to"
										onChange={this.onChange}
										onBlur={(e) => {
											e.target.value = this.getValidEmails(e.target.value);
											this.onChange(e);
										}}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-3">
								<Form.Label
									column
									sm="2"
									for="txt-email-cc"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Cc:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										defaultValue={this.state.emailAttributes.cc}
										name="cc"
										id="txt-email-cc"
										onChange={this.onChange}
										onBlur={(e) => {
											e.target.value = this.getValidEmails(e.target.value);
											this.onChange(e);
										}}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-4 pb-2">
								<Form.Label
									column
									sm="2"
									for="txt-email-bcc"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Bcc:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										defaultValue={this.state.emailAttributes.bcc}
										name="bcc"
										id="txt-email-bcc"
										onChange={this.onChange}
										onBlur={(e) => {
											e.target.value = this.getValidEmails(e.target.value);
											this.onChange(e);
										}}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-3">
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Subject:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										defaultValue={
											this.state.emailAttributes.subject ||
											`Proposal from ${this.state.company.name}`
										}
										name="subject"
										onChange={this.onChange}
									/>
								</Col>
								<Col sm="2" />
								<Col sm="10" className="mt-3">
									{this.state.clientPortal ? (
										<a
											href="#"
											onClick={async (e) => {
												e.preventDefault();
												const result = await this.launchClientPortal();
												if (!result.success) {
													console.error('Failed to open client portal');
												}
											}}
										>
											Proposals for {this.props.project.proj} - Client Portal
										</a>
									) : (
										<a
											href={this.props.sendProposalData.link}
											target="_blank"
											rel="noreferrer"
										>
											Proposals for {this.props.project.proj}
										</a>
									)}
								</Col>
							</Form.Group>

							<Form.Group as={Row} className="mb-3">
								<Form.Label
									column
									sm="2"
									for="txt-email-attachment"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Attachment:
								</Form.Label>
								<Col sm="10">
									<div className="form-group-extra">
										<Form.Control
											type="file"
											placeholder=""
											title=""
											size="sm"
											id="txt-email-attachment"
											className={`file-upload-white ${
												this.state.hasFile ? 'has-file' : ''
											}`}
											onChange={this.onFileChange}
										/>
										<span className="text-secondary-ash ri-attachment-line"></span>
									</div>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-3">
								<Form.Label
									column
									sm="2"
									for="txt-email-message"
									className="fw-700 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Message:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										as="textarea"
										rows={10}
										placeholder=""
										size="sm"
										defaultValue={
											this.state.emailAttributes.message || this.renderMessage()
										}
										name="message"
										id="txt-email-message"
										onChange={this.onChange}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-3">
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								></Form.Label>
								<Col sm="10">
									<Form.Check
										label="Include Signature from User Settings"
										name="includeUserSignature"
										onChange={this.handleChecked}
										defaultChecked={
											this.state.emailAttributes.includeUserSignature
										}
										id={`inline-md-radio-4`}
									/>
								</Col>
							</Form.Group>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={() => this.handleSave()}
								className="w-100 mb-2"
							>
								Send
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={() => {
									this.resetFields();
									this.props.hideModal();
								}}
								className="w-100"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(SendProposalModal);
