import React, { createContext, useState } from 'react';
import { usePostReconciliationsDelete } from 'api/hooks/usePostReconciliationsDelete';
import { type IDeleteReconciliationsParams as IDeleteParams } from 'api/resources/reconciliations';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { displayAlert } from 'legacy/utilities/Response';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { ECacheKeys } from 'cache/CacheKeys';

interface ISelectedAccount {
	cashAccount: string;
	statementDate: string;
}

interface ICheckbookHistoryContext {
	handleOnDelete: (account: ISelectedAccount) => void;
}

export const CheckbookHistoryContext = createContext<ICheckbookHistoryContext>({
	handleOnDelete: async () => undefined,
});

interface IReconcileContextProviderProps {
	children: React.ReactNode;
}

const CheckbookHistoryProvider = ({
	children,
}: IReconcileContextProviderProps) => {
	const queryClient = useQueryClient();
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [selectedAccount, setSelectedAccount] =
		useState<ISelectedAccount | null>(null);

	const { mutateAsync: postReconciliationsDelete } =
		usePostReconciliationsDelete();

	const openConfirmationModal = () => {
		setShowConfirmationModal(true);
	};

	const deleteReconciliation = async () => {
		if (!selectedAccount) {
			return;
		}
		try {
			const payload: IDeleteParams = {
				cashAccount: selectedAccount.cashAccount,
				statementDate: selectedAccount.statementDate,
			};

			await postReconciliationsDelete(payload);
			closeConfirmationModal();
			displayAlert(EAlertTypes.Success, 'Reconciliation deleted successfully!');
			await queryClient.invalidateQueries([ECacheKeys.Reconciliations]);
		} catch {
			displayAlert(EAlertTypes.Danger, 'Error: could not save changes');
		}
	};

	const closeConfirmationModal = () => {
		setShowConfirmationModal(false);
		setSelectedAccount(null);
	};

	const handleOnDelete = (account: ISelectedAccount) => {
		setSelectedAccount(account);
		openConfirmationModal();
	};

	return (
		<CheckbookHistoryContext.Provider
			value={{
				handleOnDelete,
			}}
		>
			{children}
			<ConfirmationModal
				show={showConfirmationModal}
				title="Checkbook Reconciliation History"
				confirmAction={deleteReconciliation}
				okBtnStyle="primary"
				labelOK="Yes"
				labelCancel="No"
				onCancel={closeConfirmationModal}
				toggleModal={closeConfirmationModal}
			>
				<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-center tw-gap-2 tw-px-2">
					<i className="ri-question-line tw-text-xl tw-text-blue-700"></i>
					<span className="tw-text-sm">
						Are you sure you want to delete the highlighted reconciliation and
						un-clear all checks and deposits in the batch?
					</span>
				</div>
			</ConfirmationModal>
		</CheckbookHistoryContext.Provider>
	);
};

CheckbookHistoryProvider.displayName = 'CheckbookHistoryProvider';

export { CheckbookHistoryProvider };
