import {
	getCoreRowModel,
	useReactTable,
	flexRender,
	getSortedRowModel,
	getFilteredRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, TableOptions } from '@tanstack/react-table';
import classNames from 'classnames';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getYear,
	getMonth,
	lastDayOfMonth,
} from 'date-fns';
import { showLoading } from 'legacy/helpers/Loading';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { DebouncedInput } from './partials/DebouncedInputs';
import React from 'react';

declare module '@tanstack/react-table' {
	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
	interface ColumnMeta<TData, TValue> {
		CustomFilterInputType: 'date';
	}
}

type TReactTableProps<T extends object> = {
	data: T[];
	isLoading?: boolean;
	expanded?: { [key: string]: boolean };
	renderExpandableComponent?: (row: T) => JSX.Element;
	columns: ColumnDef<T>[];
	/**
	 * Show arrow-down icon by default when the column is unsorted.
	 * @type {boolean}
	 * @default false
	 */
	useArrowDownIconAsDefault?: boolean;
} & TableOptions<T>;
const Table = <
	T extends object & {
		id?: string | number;
	}
>({
	data,
	expanded,
	columns,
	isLoading,
	state,
	renderExpandableComponent,
	manualFiltering,
	manualSorting,
	enableRowSelection,
	onColumnFiltersChange,
	onSortingChange,
	enableFilters,
	getRowId,
	onRowSelectionChange,
	useArrowDownIconAsDefault = false,
}: Omit<TReactTableProps<T>, 'getCoreRowModel'>) => {
	const props: TReactTableProps<T> = {
		data,
		columns,
		onColumnFiltersChange,
		enableFilters,
		state,
		enableRowSelection,
		onRowSelectionChange,
		getRowId,
		getFilteredRowModel: manualFiltering ? undefined : getFilteredRowModel(),
		getSortedRowModel: manualSorting ? undefined : getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
	};

	if (onSortingChange) {
		props.onSortingChange = onSortingChange;
	}

	const table = useReactTable(props);

	return (
		<table className="tw-w-full">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => {
							return (
								<th
									className="tw-px-6 tw-text-nowrap tw-py-5 tw-border-b-[1px] tw-border-solid tw-border-[#DEE2E6]"
									key={header.id}
									colSpan={header.colSpan}
									style={{
										width: header.getSize(),
									}}
								>
									{header.isPlaceholder ? null : (
										<div
											className={
												header.column.getCanSort()
													? 'tw-cursor-pointer tw-select-none tw-flex'
													: ''
											}
											onClick={header.column.getToggleSortingHandler()}
											title={
												header.column.getCanSort()
													? header.column.getNextSortingOrder() === 'asc'
														? 'Sort ascending'
														: header.column.getNextSortingOrder() === 'desc'
														? 'Sort descending'
														: 'Clear sort'
													: undefined
											}
										>
											<span>
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
											</span>
											{{
												asc: (
													<i className="ri-arrow-up-s-fill tw-text-[#808080]" />
												),
												desc: (
													<i className="ri-arrow-down-s-fill tw-text-[#808080]" />
												),
												false:
													useArrowDownIconAsDefault &&
													header.column.getCanSort() ? (
														<i className="ri-arrow-down-s-fill tw-text-[#BDBDBD]" />
													) : null,
											}[header.column.getIsSorted() as string] ?? null}
										</div>
									)}
									{!header.column.getCanFilter() && enableFilters && (
										<div className="tw-pt-[43px]" />
									)}
									{header.column.getCanFilter() && enableFilters ? (
										<div className="tw-pt-2">
											{header.column.columnDef.meta?.CustomFilterInputType ===
											'date' ? (
												<DateRangePicker
													style={{
														minWidth: '200px',
													}}
													value={
														(header.column.getFilterValue() as DateRange) ||
														null
													}
													onChange={header.column.setFilterValue}
													placement="auto"
													placeholder="Select date"
													format="MM/dd/yyyy"
													ranges={[
														{
															label: 'today',
															value: [
																startOfDay(new Date()),
																endOfDay(new Date()),
															],
														},
														{
															label: 'yesterday',
															value: [
																startOfDay(addDays(new Date(), -1)),
																endOfDay(addDays(new Date(), -1)),
															],
														},
														{
															label: 'last7Days',
															value: [
																startOfDay(subDays(new Date(), 6)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'Last 30 Days',
															value: [
																startOfDay(subDays(new Date(), 30)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'This month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()),
																		1
																	)
																),
																endOfDay(lastDayOfMonth(new Date())),
															],
														},
														{
															label: 'Last month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																),
																endOfDay(
																	lastDayOfMonth(
																		new Date(
																			getYear(new Date()),
																			getMonth(new Date()) - 1,
																			1
																		)
																	)
																),
															],
														},
													]}
												/>
											) : (
												<DebouncedInput
													type="text"
													value={
														(header.column.getFilterValue() as string) || ''
													}
													onChange={(value) =>
														header.column.setFilterValue(value)
													}
												/>
											)}
										</div>
									) : null}
								</th>
							);
						})}
					</tr>
				))}
			</thead>
			<tbody>
				{isLoading
					? showLoading()
					: table.getRowModel().rows.map((row, index) => (
							<React.Fragment key={row.id}>
								<tr
									data-id={row.original.id}
									className="hover:tw-shadow-[0_4px_20px_rgba(0,0,0,0.196)] hover:tw-z-20"
								>
									{row.getVisibleCells().map((cell) => (
										<td
											className={classNames(
												'tw-px-6 tw-py-5',
												index % 2 !== 0
													? 'tw-shadow-[inset_0_0_0_9999px_#faf9f7]'
													: '',
												index % 2 !== 0
													? 'tw-border-t-[1px] tw-border-solid tw-border-[#DEE2E6]'
													: '',
												index % 2 !== 0
													? 'tw-border-b-[1px] tw-border-solid tw-border-[#DEE2E6]'
													: ''
											)}
											key={cell.id}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									))}
								</tr>
								{expanded?.[String(row.original.id)] && (
									<tr className="tw-px-6 tw-py-5">
										<td
											className="tw-px-6 tw-py-5"
											colSpan={table.getAllColumns().length + 1}
										>
											{renderExpandableComponent?.(row.original)}
										</td>
									</tr>
								)}
							</React.Fragment>
					  ))}
			</tbody>
		</table>
	);
};

Table.displayName = 'Table';

export default Table;
