import URI from 'legacy/defaults/RoutesDefault';
import React from 'react';
import { Link } from 'react-router-dom';
export const Placeholder = () => (
	<div className="tw-flex-col tw-flex tw-items-center">
		<p className="tw-m-0 tw-text-lg">
			Want to process your bank transactions through Design Manager faster?
		</p>
		<p className="tw-m-0 tw-text-lg">
			Connect your account today.
			<Link
				className="tw-pl-2"
				to={`${URI.accountSettings.base}/plaid-accounts`}
			>
				Click to add a new bank connection.
			</Link>
		</p>
		<div className="tw-w-1/2 tw-pt-8">
			<img src="/Plaid/PlaceholderTransactions.png" />
		</div>
	</div>
);
Placeholder.displayName = 'Placeholder';
