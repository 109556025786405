import {
	UseMutationOptions,
	UseMutationResult,
	useMutation,
} from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { Item } from '../types/Item';

const api = new ApiService();

const useCreateItem = (
	id: number,
	options?: UseMutationOptions<Item, unknown, void, unknown>
): UseMutationResult<Item, unknown, void, unknown> => {
	return useMutation({
		...(options || {}),
		mutationFn: async () => {
			if (!id) {
				throw new Error('Invalid project ID');
			}

			const createPayload = {
				projectId: id,
			};

			return await api.createProjectItem(createPayload);
		},
	});
};
export default useCreateItem;
