import React, { FC } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { showEmpty } from 'legacy/helpers/Loading';
import dayjs from 'dayjs';
import { currencyFormat } from 'legacy/helpers/Number';
import { Link } from 'react-router-dom';
import { TInventoryTransaction } from '../../../../../lib/api/types/InventoryTransaction';

interface ITransactionRow {
	title: string;
	id: string;
	formattedValue?: (value: any) => string;
	render?: (value: TInventoryTransaction) => JSX.Element | null;
}

export const TransactionsTable: FC<{
	data: TInventoryTransaction[];
	selectedIds: number[];
	onTransaction: (transaction: TInventoryTransaction) => void;
	onAdd: () => void;
	onDelete: (ids: number[]) => void;
	onCheck: (id: number) => void;
	onCheckAll: () => void;
}> = ({
	data,
	selectedIds,
	onTransaction,
	onAdd,
	onDelete,
	onCheck,
	onCheckAll,
}) => {
	const model: () => ITransactionRow[] = () => {
		const formatDate = (value: string, format: string) => {
			return dayjs(value).format(format);
		};
		const formatPrice = (value: string) => {
			return currencyFormat(value);
		};
		const renderLink = (value: any, onClick: () => void) => {
			return (
				<Link to="#" className="text-charcoal" onClick={onClick}>
					{value}
				</Link>
			);
		};

		return [
			{
				title: 'TX#',
				id: 'txnum',
				render: (model: TInventoryTransaction) => {
					return model.type == 'Adj.'
						? renderLink(model.txnum, () => {
								onTransaction(model);
						  })
						: null;
				},
			},
			{
				title: 'Date',
				id: 'userdate',
				formattedValue: (value) => formatDate(value, 'MM/DD/YYYY'),
			},
			{ title: 'Type', id: 'type' },
			{ title: 'WH', id: 'wh' },
			{
				title: 'On Hand',
				id: 'onhandadj',
			},
			{
				title: 'Paid For',
				id: 'qtypaidforadj',
			},
			{
				title: 'Committed',
				id: 'commitadj',
			},
			{
				title: 'On Order',
				id: 'onorderadj',
			},
			{
				title: 'Cost',
				id: 'costadj',
				formattedValue: (value) => formatPrice(value),
			},
			{ title: 'Cost Description', id: 'costdesc' },
			{ title: 'TX Description', id: 'txdesc' },
			{ title: 'Project', id: 'proj' },
			{ title: 'Item', id: 'item' },
			{ title: 'Component', id: 'comp' },
			{ title: 'Client Inv', id: 'invnum' },
			{ title: 'Vendor Inv', id: 'sinvtxnum' },
			{ title: 'PO', id: 'ponum' },
			{
				title: 'Fiscal Month',
				id: 'fiscalmonth',
				formattedValue: (date) => formatDate(date, 'MM/YYYY'),
			},
			{
				title: 'Entry Date',
				id: 'computerdate',
				formattedValue: (date) => formatDate(date, 'MM/DD/YYYY hh:mm A '),
			},
		];
	};

	const handleDelete = () => {
		onDelete(selectedIds);
	};

	return (
		<div>
			<div
				style={{
					padding: '16px',
				}}
				className="d-flex roundedx-6 bg-ivory border-1 border-sand justify-content-between align-items-center px-4"
			>
				<h6 className="fw-bold mb-0">Transactions</h6>
				<div className="d-flex flex-row">
					<Button
						variant="primary"
						size="sm"
						className={`btn-icon btn-action`}
						onClick={onAdd}
					>
						<i className="ri-add-line ri-lg"></i> Add
					</Button>

					<Button
						variant="ivory"
						size="sm"
						className={`btn-icon btn-action ms-3`}
						disabled={
							selectedIds.length === 0 ||
							selectedIds.some((id) =>
								data.some((item) => item.txnum === id && item.type !== 'Adj.')
							)
						}
						onClick={handleDelete}
					>
						<i className="ri-close-line"></i> Delete
					</Button>
				</div>
			</div>
			<div className="tw-max-h-[260px] tw-overflow-y-auto">
				<Table striped className="a-table">
					<thead
						className="bg-light position-sticky top-0"
						style={{ zIndex: 99 }}
					>
						<tr>
							<th>
								<Form.Check
									label=""
									type="checkbox"
									checked={selectedIds.length > 0}
									className={selectedIds.length > 0 ? 'line' : ''}
									onChange={onCheckAll}
								/>
							</th>
							{model().map((item, index) => (
								<th key={index}>{item.title}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.length
							? data.map((transaction: any, index) => (
									<tr key={index}>
										<td>
											<Form.Check
												label=""
												type="checkbox"
												checked={selectedIds.includes(transaction.txnum)}
												onChange={() => {
													onCheck(transaction.txnum);
												}}
											/>
										</td>
										{model().map((item, index) => (
											<td key={index}>
												{item.render?.(transaction) ??
													item.formattedValue?.(transaction[item.id]) ??
													transaction[item.id]}
											</td>
										))}
									</tr>
							  ))
							: showEmpty()}
					</tbody>
				</Table>
			</div>
		</div>
	);
};
TransactionsTable.displayName = 'TransactionsTable';
