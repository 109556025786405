import { apiClient } from 'api/apiClient';

export interface ITransactionSuggestions {
	type: `${ETransactionSuggestionsType}`;
	description: string;
	details: ITransactionsDetails[];
}

export interface ITransactionsDetails {
	id: string;
	date: string;
	amount: number;
	poName: string;
}

export enum ETransactionSuggestionsType {
	INVOICE = 'objInvoices',
	PURCHASEORDER = 'objPO',
}

const RESOURCE = (id: number) => `/Transactions/${id}/suggestions`;

export const getSuggestions = async (
	id: number
): Promise<ITransactionSuggestions[]> => {
	const { data } = await apiClient.get(`${RESOURCE(id)}`);

	return data;
};

export const postSuggestions = async (
	id: number,
	suggestions: string[]
): Promise<ITransactionSuggestions[]> => {
	const { data } = await apiClient.post(`${RESOURCE(id)}`, {
		ids: suggestions,
	});

	return data;
};
