import React from 'react';
export const Placeholder = () => (
	<div className="tw-flex-col tw-flex tw-items-center">
		<p className="tw-m-0 tw-text-lg">
			Want to process your bank transactions through Design Manager faster?
		</p>
		<p className="tw-m-0 tw-text-lg">
			Connect your account today. Click Add to connect your bank using Plaid.
		</p>
		<div className="tw-w-1/2 tw-pt-8">
			<img src="/Plaid/PlaceholderPlaidAccounts.png" />
		</div>
	</div>
);
Placeholder.displayName = 'Placeholder';
