import React, { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { FooterFormAction } from '../../../components/Form';
import { ClientPortalSettingsHeader } from './partials/ClientPortalSettingsHeader';
import ClientPortalEnableButton from './partials/ClientPortalEnableButton';
import { ClientPortalColorScheme } from './partials/ClientPortalColorScheme';
import { ClientPortalFeatureToggles } from './partials/ClientPortalFeatureToggles';
import {
	displayAlertSuccess,
	displayAlertError,
} from 'legacy/utilities/Response';
import {
	useClientPortalSettings,
	IClientPortalSettings,
} from 'legacy/hooks/useClientPortalSettings';
import { useGetCompanyTwo } from 'api/hooks/useGetCompanyTwo';

const ClientPortalSettingsPage = () => {
	const { updateClientPortalSettings, isLoading } = useClientPortalSettings();
	const { data: companyTwo, refetch } = useGetCompanyTwo();

	const methods = useForm<IClientPortalSettings>({
		mode: 'onChange',
	});

	const { formState, reset } = methods;
	const { isDirty, isValid, dirtyFields } = formState;

	useEffect(() => {
		if (companyTwo) {
			const clientPortalSettings: IClientPortalSettings = {
				clientPortalEnabled: companyTwo.clientPortalEnabled ?? false,
				clientPortalLogoUrl: companyTwo.clientPortalLogoUrl ?? '',
				clientPortalColorPrimary:
					companyTwo.clientPortalColorPrimary ?? '#6B9080',
				clientPortalColorSecondary:
					companyTwo.clientPortalColorSecondary ?? '#1B263B',
				clientPortalShowProjectBudget:
					companyTwo.clientPortalShowProjectBudget ?? false,
				clientPortalAllowSendMessage:
					companyTwo.clientPortalAllowSendMessage ?? false,
				clientPortalMessageRecipient:
					companyTwo.clientPortalMessageRecipient ?? '',
				clientPortalItemsApproval:
					companyTwo.clientPortalItemsApproval ?? false,
				clientPortalProposalsApproval:
					companyTwo.clientPortalProposalsApproval ?? false,
				clientPortalProposalsRequireSignature:
					companyTwo.clientPortalProposalsRequireSignature ?? false,
			};

			reset(clientPortalSettings);
		}
	}, [companyTwo, reset]);

	const onSubmit = async (data: IClientPortalSettings) => {
		if (
			data.clientPortalAllowSendMessage &&
			!data.clientPortalMessageRecipient
		) {
			methods.setError('clientPortalMessageRecipient', {
				type: 'manual',
				message: 'Please provide at least one email address.',
			});
			return;
		}

		const changedValues: Partial<IClientPortalSettings> = {};

		Object.keys(dirtyFields).forEach((key) => {
			const typedKey = key as keyof IClientPortalSettings;
			const value = data[typedKey];

			if (value !== undefined) {
				changedValues[typedKey] = value;
			}
		});

		if (Object.keys(changedValues).length === 0) {
			displayAlertSuccess('No changes to save.');
			return;
		}

		try {
			await updateClientPortalSettings(changedValues);
			await refetch();
			displayAlertSuccess('Client portal settings saved successfully.');
		} catch (error) {
			displayAlertError(
				'An error occurred while saving settings. Please try again.'
			);
		}
	};

	return (
		<FormProvider
			resetField={methods.resetField}
			getFieldState={methods.getFieldState}
			handleSubmit={methods.handleSubmit}
			watch={methods.watch}
			setError={methods.setError}
			getValues={methods.getValues}
			reset={methods.reset}
			setValue={methods.setValue}
			clearErrors={methods.clearErrors}
			control={methods.control}
			register={methods.register}
			unregister={methods.unregister}
			setFocus={methods.setFocus}
			trigger={methods.trigger}
			formState={methods.formState}
		>
			<ClientPortalSettingsHeader />
			<div className="content-padding min-height has-action-bar">
				<Container fluid>
					<form
						className="tw-w-[60%]"
						onSubmit={methods.handleSubmit(onSubmit)}
					>
						<ClientPortalEnableButton />
						<ClientPortalColorScheme />
						<ClientPortalFeatureToggles />
					</form>
				</Container>
			</div>
			<FooterFormAction>
				<Button
					variant="primary"
					size="lg"
					onClick={methods.handleSubmit(onSubmit)}
					disabled={!isDirty || !isValid || isLoading}
				>
					{isLoading ? 'Saving...' : 'Save'}
				</Button>
			</FooterFormAction>
		</FormProvider>
	);
};

ClientPortalSettingsPage.displayName = 'ClientPortalSettingsPage';

export { ClientPortalSettingsPage };
