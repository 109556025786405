import React, { useCallback, useMemo, useState } from 'react';
import type { ITemporaryReconciliationDeposit } from 'api/resources/temporaryReconciliations';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	Row as TTableRow,
	FilterFn,
} from '@tanstack/react-table';
import Table from 'legacy/app/components/newTable/Table';
import { formatDate } from 'legacy/utilities/formatDate';
import { formatReconciliationCurrency } from 'legacy/utilities/formatReconciliationCurrency';
import { CheckbookReconcileCheckboxCell } from './CheckbookReconcileCheckboxCell';
import { CheckbookReconcileCheckboxHeader } from './CheckbookReconcileCheckboxHeader';
import { startOfDay, endOfDay } from 'date-fns';

const CheckbookReconcileDepositsTable = () => {
	const {
		tab,
		outstandingDeposits,
		clearedDeposits,
		isSearchEnabled,
		isGetDepositsLoading,
		selectedDeposits,
		depositsSelectAll,
		handleOnDepositSelectedChange,
		handleOnDepositsSelectAll,
	} = useCheckbookReconcile();

	const isOutstandingTab = tab === 'outstanding';

	const handleOnSelectAll = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			handleOnDepositsSelectAll(e.target.checked);
		},
		[handleOnDepositsSelectAll]
	);

	const handleOnChange = useCallback(
		async (
			e: React.ChangeEvent<HTMLInputElement>,
			row?: TTableRow<ITemporaryReconciliationDeposit>
		) => {
			if (!row || !row.id) return;
			await handleOnDepositSelectedChange(Number(row.id), e.target.checked);
		},
		[handleOnDepositSelectedChange]
	);

	const [
		columnFiltersOutstandingDeposits,
		setColumnFiltersOutstandingDeposits,
	] = useState<ColumnFiltersState>([]);
	const [columnFiltersClearedDeposits, setColumnFiltersClearedDeposits] =
		useState<ColumnFiltersState>([]);

	const [sortingOutstandingDeposits, setSortingOutstandingDeposits] =
		useState<SortingState>([]);
	const [sortingClearedDeposits, setSortingClearedDeposits] =
		useState<SortingState>([]);

	const columns = useMemo<ColumnDef<ITemporaryReconciliationDeposit>[]>(() => {
		const dateBetweenFilterFn: FilterFn<ITemporaryReconciliationDeposit> = (
			row,
			columnId,
			filterValue: [string | undefined, string | undefined]
		): boolean => {
			const dateString = row.getValue<string | undefined>(columnId);
			if (!dateString) return false;
			const [startDate, endDate] = filterValue ?? [];
			const date = new Date(dateString);
			const isAfterStartDate = startDate
				? date >= startOfDay(new Date(startDate))
				: true;
			const isBeforeEndDate = endDate
				? date <= endOfDay(new Date(endDate))
				: true;

			return isAfterStartDate && isBeforeEndDate;
		};

		return [
			{
				id: 'cleared',
				header: () =>
					CheckbookReconcileCheckboxHeader({
						handleOnChange: handleOnSelectAll,
						checked: isOutstandingTab
							? depositsSelectAll.outstanding
							: depositsSelectAll.cleared,
						disabled: isOutstandingTab
							? outstandingDeposits.length === 0
							: clearedDeposits.length === 0,
					}),
				size: 25,
				cell: ({ row }) =>
					CheckbookReconcileCheckboxCell({
						row,
						handleOnChange,
						checked: selectedDeposits[row.id],
					}),
				enableColumnFilter: false,
			},
			{
				accessorKey: 'depositslip',
				header: 'Slip #',
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				id: 'userdate',
				accessorFn: (col) => col.userdate,
				header: 'Date',
				size: 50,
				cell: (info) => formatDate(info.getValue<string>() ?? ''),
				filterFn: dateBetweenFilterFn,
				meta: {
					CustomFilterInputType: 'date',
				},
			},
			{
				accessorKey: 'amount',
				header: 'Amount',
				size: 50,
				filterFn: 'includesString',
				cell: (info) => {
					const value = info.getValue<number>() ?? 0;
					return formatReconciliationCurrency(value);
				},
				sortingFn: (rowA, rowB, columnId) => {
					const aValue = rowA.getValue<number>(columnId) ?? 0;
					const bValue = rowB.getValue<number>(columnId) ?? 0;
					return aValue - bValue;
				},
			},
		];
	}, [
		selectedDeposits,
		handleOnChange,
		depositsSelectAll,
		handleOnSelectAll,
		isOutstandingTab,
		clearedDeposits,
		outstandingDeposits,
	]);

	return (
		<div className="tw-pb-12">
			<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-between tw-bg-[#eeede9] tw-p-4 tw-border tw-rounded-md">
				<span className="tw-font-bold tw-text-lg">Deposits</span>
			</div>

			<div className="tw-h-96 tw-overflow-auto tw-pb-4">
				{isOutstandingTab ? (
					<Table
						key="outstandingDeposits"
						columns={columns}
						isLoading={isGetDepositsLoading}
						state={{
							columnFilters: columnFiltersOutstandingDeposits,
							sorting: sortingOutstandingDeposits,
						}}
						onSortingChange={setSortingOutstandingDeposits}
						onColumnFiltersChange={setColumnFiltersOutstandingDeposits}
						enableFilters={isSearchEnabled}
						data={outstandingDeposits}
						getRowId={(deposit) => deposit.recnum.toString()}
						useArrowDownIconAsDefault={true}
					/>
				) : (
					<Table
						key="clearedDeposits"
						columns={columns}
						isLoading={isGetDepositsLoading}
						state={{
							columnFilters: columnFiltersClearedDeposits,
							sorting: sortingClearedDeposits,
						}}
						onSortingChange={setSortingClearedDeposits}
						onColumnFiltersChange={setColumnFiltersClearedDeposits}
						enableFilters={isSearchEnabled}
						data={clearedDeposits}
						getRowId={(deposit) => deposit.recnum.toString()}
						useArrowDownIconAsDefault={true}
					/>
				)}
			</div>
		</div>
	);
};

CheckbookReconcileDepositsTable.displayName = 'CheckbookReconcileDepositsTable';

export { CheckbookReconcileDepositsTable };
