import React from 'react';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';

export type TTabOption = 'New' | 'Assigned';

type TTabsProps = {
	toggleTab: (tab: TTabOption) => void;
	activeTab: TTabOption;
};

export const TransactionTabs = ({
	toggleTab,
	activeTab,
}: TTabsProps): JSX.Element => (
	<ul className="tw-flex tw-gap-4 tw-items-center tw-p-0 tw-pt-4 tw-list-none">
		<li>
			<BaseButton
				buttonClass={`tw-text-white tw-cursor-pointer tw-py-3 tw-px-6 tw-rounded-md tw-text-sm ${
					activeTab === 'New' ? 'tw-bg-[#ffffff26]' : 'tw-bg-[transparent]'
				}`}
				onClick={() => toggleTab('New')}
				text="New"
			/>
		</li>
		<li>
			<BaseButton
				buttonClass={`tw-text-white tw-cursor-pointer tw-py-3 tw-px-6 tw-rounded-md tw-text-sm ${
					activeTab === 'Assigned' ? 'tw-bg-[#ffffff26]' : 'tw-bg-[transparent]'
				}`}
				onClick={() => toggleTab('Assigned')}
				text="Assigned"
			/>
		</li>
	</ul>
);

TransactionTabs.displayName = 'TransactionTabs';
