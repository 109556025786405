import React, { useState } from 'react';
import URI from 'legacy/defaults/RoutesDefault';
import {
	SECURITY_ATTRIBUTE_TYPES,
	useHasAccess,
} from 'legacy/app/context/security';

import Header from 'legacy/templates/components/Header';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import cn from 'classnames';

type TTab = 'reconcile' | 'history';
interface ICheckbookReconcileHeaderProps {
	tab: TTab;
}
export const CheckbookReconcileHeader = ({
	tab,
}: ICheckbookReconcileHeaderProps) => {
	const [activeTab, setActiveTab] = useState<TTab>(tab);

	const hasReconcileAccess = useHasAccess(
		60,
		SECURITY_ATTRIBUTE_TYPES.DenyAccess
	);
	const hasReconcileHistoryAccess = useHasAccess(
		60,
		SECURITY_ATTRIBUTE_TYPES.DenySpec1
	);
	return (
		<Header buttons={1}>
			<Header.TitleContent>
				<Header.LeftContent>
					<Header.Breadcumbs>
						<NavLink
							to={URI.accountsPayable.checkbook.list}
							className="text-white active d-flex align-items-center text-decoration-none fw-bold me-4 mb-3"
						>
							<i className="ri-arrow-left-s-line"></i> Back
						</NavLink>
						<Breadcrumb className="breadcrumb-light">
							<Breadcrumb.Item
								linkProps={{ to: URI.accountsPayable.creditCard.list }}
								linkAs={Link}
							>
								Accounts Payable
							</Breadcrumb.Item>
							<Breadcrumb.Item
								linkProps={{ to: URI.accountsPayable.checkbook.list }}
								linkAs={Link}
							>
								Checkbook
							</Breadcrumb.Item>
							<Breadcrumb.Item>Reconcile</Breadcrumb.Item>
						</Breadcrumb>
					</Header.Breadcumbs>
					<Header.Title>
						{' '}
						{tab === 'reconcile'
							? 'Checkbook Reconcile'
							: 'Reconcile Checkbook History'}
					</Header.Title>
				</Header.LeftContent>
			</Header.TitleContent>
			<Header.Buttons>
				{hasReconcileAccess && (
					<li>
						<Link
							to={URI.accountsPayable.checkbook.reconcile}
							className={cn('btn text-white', {
								active: activeTab === 'reconcile',
							})}
							onClick={() => setActiveTab('reconcile')}
						>
							Reconcile
						</Link>
					</li>
				)}
				{hasReconcileHistoryAccess && (
					<li>
						<Link
							to={URI.accountsPayable.checkbook.history}
							className={cn('btn text-white', {
								active: activeTab === 'history',
							})}
							onClick={() => setActiveTab('history')}
						>
							Reconcile History
						</Link>
					</li>
				)}
			</Header.Buttons>
		</Header>
	);
};

CheckbookReconcileHeader.displayName = 'CheckbookReconcileHeader';
