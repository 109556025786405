import { Row } from '@tanstack/react-table';
import { ITransactions } from 'api/resources/transactions';
import { Badge } from 'react-bootstrap';

export const TransactionTableBadge = ({
	row,
	toggleExpanded,
}: {
	row: Row<ITransactions>;
	toggleExpanded: (id: string) => void;
}) => (
	<Badge
		pill
		onClick={() => toggleExpanded(String(row.original.id))}
		className="!tw-text-sm !tw-pl-6 !tw-pr-6 !tw-font-normal tw-cursor-pointer tw-select-none !tw-bg-[#006A53]"
	>
		Assign
	</Badge>
);
TransactionTableBadge.displayName = 'TransactionTableBadge';
