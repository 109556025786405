export enum ECacheKeys {
	JournalEntries = 'JournalEntries',
	CreditCard = 'CreditCard',
	FinanceCharges = 'FinanceCharges',
	Clients = 'Clients',
	ClientInvoices = 'ClientInvoices',
	StatusCodes = 'StatusCodes',
	Proposals = 'Proposals',
	Invoices = 'Invoices',
	ItemTotals = 'ItemTotals',
	PurchaseOrders = 'PurchaseOrders',
	VendorInvoices = 'VendorInvoices',
	VendorDefaults = 'VendorDefaults',
	PlaidToken = 'PlaidToken',
	PayTypes = 'PayTypes',
	Transactions = 'Transactions',
	PlaidAccounts = 'PlaidAccounts',
	Reconciliations = 'Reconciliations',
	RunReports = 'RunReports',
	PublicMappings = 'PublicMappings',
	TemporaryReconciliations = 'TemporaryReconciliations',
	TransactionSuggestions = 'TransactionSuggestions',
	PostTransactionSuggestions = 'PostTransactionSuggestions',
	ClearTransactions = 'ClearTransactions',
	CompanyTwo = 'CompanyTwo',
	Company = 'Company',
	Warehouses = 'Warehouses',
	InventoryStocks = 'InventoryStocks',
	ClientPortalToken = 'ClientPortalToken',
}
