import { useMutation } from '@tanstack/react-query';
import { postSuggestions } from 'api/resources/transactionSuggestions';
import { ECacheKeys } from 'cache/CacheKeys';

export const usePostSuggestions = () => {
	return useMutation(
		({ id, suggestions }: { id: number; suggestions: string[] }) =>
			postSuggestions(id, suggestions),
		{ mutationKey: [ECacheKeys.PostTransactionSuggestions] }
	);
};
