import { useGetTransactionSuggestions } from 'api/hooks/useGetTransactionSuggestions';
import {
	ETransactionSuggestionsType,
	ITransactionSuggestions,
} from 'api/resources/transactionSuggestions';
import classNames from 'classnames';
import Spinner from 'legacy/app/components/help/Spinner';
import { formatDate } from 'legacy/helpers/Date';
import { displayAlertError } from 'legacy/utilities/Response';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const normalisedTypes = {
	[ETransactionSuggestionsType.PURCHASEORDER]: 'Purchase Order',
	[ETransactionSuggestionsType.INVOICE]: 'Invoice',
};

export const ExpandablePanelSuggestions = ({
	id,
	setSelectedSuggestionsDetails,
	setSelectedSuggestion,
	selectedSuggestion,
}: {
	id: number;
	selectedSuggestion: string;
	setSelectedSuggestion: (value: string) => void;
	setSelectedSuggestionsDetails: (value: string[]) => void;
}) => {
	const {
		data: suggestions,
		isLoading,
		error,
	} = useGetTransactionSuggestions(id);

	const getSuggestionDescription = (
		suggestion: ITransactionSuggestions,
		poName?: string
	) => {
		const hasName =
			(poName && poName !== '-') ||
			(suggestion.details[0].poName && suggestion.details[0].poName !== '-');

		if (suggestion.type === ETransactionSuggestionsType.PURCHASEORDER) {
			return (
				<>
					{hasName && (
						<span className="tw-text-black tw-font-normal tw-text-base">
							{poName || suggestion.details[0].poName}
						</span>
					)}
					<span className="tw-text-black tw-font-normal tw-text-base">
						Vendor: {suggestion.description}
					</span>
				</>
			);
		}
		return (
			<>
				<span className="tw-text-black tw-font-normal tw-text-base">
					Vendor: {suggestion.description}
				</span>
				{hasName && (
					<span className="tw-text-black tw-font-normal tw-text-base">
						PO: {poName || suggestion.details[0].poName}
					</span>
				)}
			</>
		);
	};

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error loading the suggestions. Please try again later.'
			);
		}
	}, [error]);

	if (isLoading) {
		return <Spinner isChild />;
	}

	if (suggestions && !suggestions.length) {
		return <p className="tw-text-base">No suggestions available</p>;
	}

	return suggestions ? (
		<div>
			<p className="tw-text-base">Matching records found:</p>
			{suggestions.map((suggestion, index) => (
				<div
					key={`${suggestion.type} ${suggestion.description} ${index}`}
					className="tw-flex tw-justify-between tw-items-center tw-mt-4 tw-pl-4"
				>
					<div
						className={classNames(
							'tw-flex tw-gap-4 tw-items-center',
							suggestion.details.length > 1 && 'tw-flex-col'
						)}
					>
						<div className="tw-flex tw-gap-4">
							<Form.Check
								inline
								onChange={() => {
									setSelectedSuggestion(
										`${suggestion.type} ${suggestion.description} ${index}`
									);
									setSelectedSuggestionsDetails(
										suggestion.details.map((detail) => detail.id)
									);
								}}
								checked={
									selectedSuggestion ===
									`${suggestion.type} ${suggestion.description} ${index}`
								}
								type="radio"
								label=""
								id={`${suggestion.type} ${suggestion.description} ${index}`}
							/>
							<label
								htmlFor={`${suggestion.type} ${suggestion.description} ${index}`}
								className="tw-text-base tw-text-[#008b6d] tw-flex tw-gap-4 tw-font-bold"
							>
								{normalisedTypes[suggestion.type]}
							</label>
						</div>
						{suggestion.details.length === 1 ? (
							<>
								{getSuggestionDescription(suggestion)}
								<p className="tw-text-base tw-m-0 tw-flex tw-gap-4">
									{formatDate(suggestion.details[0].date)}
									<span>
										{suggestion.details[0].amount.toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
										})}
									</span>
								</p>
							</>
						) : (
							<div>
								{suggestion.details.map((detail) => (
									<div key={detail.id}>
										<p className="tw-text-base tw-m-0 tw-flex tw-gap-4 tw-pl-12">
											{getSuggestionDescription(suggestion, detail.poName)}
											<span>{formatDate(detail.date)}</span>
											<span>
												{detail.amount.toLocaleString('en-US', {
													style: 'currency',
													currency: 'USD',
												})}
											</span>
										</p>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	) : null;
};
ExpandablePanelSuggestions.displayName = 'ExpandablePanelSuggestions';
