import { Form, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ClientPortalColorScheme = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="tw-mb-4 tw-ml-4">
			<h6 className="mb-3">Portal Color Scheme</h6>
			<Col className="tw-w-full tw-flex tw-flex-col tw-items-end">
				<Form.Group className="mb-3 tw-flex tw-justify-between tw-items-center tw-w-[95%]">
					<Form.Label>Primary Color</Form.Label>
					<Form.Control
						type="color"
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('clientPortalColorPrimary')}
						className="w-16 p-0 appearance-none transform scale-75"
						isInvalid={!!errors.primaryColor}
					/>

					<Form.Control.Feedback type="invalid">
						{typeof errors.sendMessageTo?.message === 'string'
							? errors.sendMessageTo?.message
							: ''}
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className="mb-3 tw-flex tw-justify-between tw-items-center tw-w-[95%]">
					<Form.Label>Secondary Color</Form.Label>
					<Form.Control
						type="color"
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('clientPortalColorSecondary')}
						className="w-16 p-0 appearance-none transform scale-75"
						isInvalid={!!errors.secondaryColor}
					/>

					<Form.Control.Feedback type="invalid">
						{typeof errors.sendMessageTo?.message === 'string'
							? errors.sendMessageTo?.message
							: ''}
					</Form.Control.Feedback>
				</Form.Group>
			</Col>
		</div>
	);
};

ClientPortalColorScheme.displayName = 'ClientPortalColorScheme';
export { ClientPortalColorScheme };
