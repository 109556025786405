import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ICheckInventory } from 'api/resources/inventoryStocks';
import { getInventoryOnHand } from 'api/resources/inventoryStocks';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetInventoryOnHand = (
	filter: string,
	options?: UseQueryOptions<ICheckInventory>
) => {
	const query = useQuery<ICheckInventory>({
		queryKey: [ECacheKeys.InventoryStocks, 'InventoryOnHand', filter],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getInventoryOnHand(filter),
		...(options || {}),
	});

	return query;
};
