import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ClientPortalEnablePortal = () => {
	const { register } = useFormContext();

	return (
		<Form.Group className="mb-4">
			<div className="d-flex justify-content-between align-items-center">
				<Form.Label className="mb-0">Enable Client Portal</Form.Label>
				<Form.Check
					type="switch"
					id="enablePortal"
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...register('clientPortalEnabled')}
					className="ms-3 tw-scale-[1.2]"
				/>
			</div>
		</Form.Group>
	);
};

ClientPortalEnablePortal.displayName = 'ClientPortalEnablePortal';
export default ClientPortalEnablePortal;
